/*========================================================
                         Box
=========================================================*/
* {
	+ .box.latest,	
	+ .box.bestseller,
	+ .box.single-category {
		margin-top: 30px;
	}
	+ .box.single-category {
		margin-top: 52px;
		.box{
			margin-bottom: 18px;
		}
		.box-heading{
			margin-bottom: 0;
		}
	}
	+ .box.specials {
		margin-top: 47px;
		@media (max-width: 991px) and (min-width: 768px) {
			margin-top: 27px;
		}
	}
}
.box {
	margin-bottom: 30px;
	.box-heading {
		margin: 0 0 33px;
		h2, h3 {
			margin: 0;
			font-weight: 700;
			font-size: 18px;
			line-height: 22px;
			text-transform:uppercase;
		}
		[id*="nav_"] {
			margin-top: 0;
			@media (max-width: 767px) {
				margin-top: -10px;
			}
		}
	}
	&.single-category {
		margin-bottom: 0;
	}
}

.box.manufacturer {
	.box-heading {
		margin-bottom:42px;
	}
	.swiper-slide {
		height:81px;
		line-height:81px;
		> a {
			vertical-align:middle;
		}
	}
	.img-responsive {	
	}
	a img {
		@include transition(.3s all ease);
		max-height:81px;
		opacity:.7;
	}
	a:hover img {
		opacity:1;
	}
}

aside .box {
	.box-heading {
		margin-bottom: 20px;
		padding-bottom: 15px;
		border-bottom: 1px solid $borderColor;
		text-transform: uppercase;
		[id*="nav_"] {
			white-space: nowrap;
			.swiper-button-prev, .swiper-button-next {
				position:relative;
				float:none !important;
				left:auto !important;
				right:auto !important;
			}
		}
	}
	.swiper-slide .product-thumb .image {
		margin-left:1px;
		margin-right:1px;
	}
	.product-layout {
		width: 100%;
		+ .product-layout {
			margin-top: 30px;
		}
	}
}

.box-payments {
	list-style-type: none;
	@include clearfix();
	padding: 0;
	li {
		display: inline-block;
		margin: 0 0 4px;
	}
}

.box-logo {
	display: inline-block;
}

.social-list {
	margin-top: -10px;
	@include clearfix();
	li {
		margin: 0;
		display: inline-block;
		text-align: center;
		&, a {
			display: inline-block;
		}
		a {
			width: 49px;
			height: 49px;
			@include border-radius(50%);
			font-size: 16px;
			line-height: 49px;
			background: $primary !important;
			border: 2px solid $primary;
			&, &:visited, &:focus {
				color: $white;
				background: transparent;
			}
			&:hover {
				color: $black;
				background: transparent !important;
			}
			@media (max-width: 1440px) {
				height: 38px;
				width: 38px;
				line-height: 38px;
			}
		}
		+ li {
			margin-left: 7px;
			html[dir="rtl"] & {
				margin-left: 0;
				margin-right: 10px;
			}
		}
	}
}

.box {
	aside[class][id] & {
		.box-heading {
			text-align: center;
		}
		[id*="nav_"] {
			display: inline-block;
			float: none;
			margin-top: 15px;
		}
	}
	[id*="nav_"] {
		position: relative;
		@include clearfix();
		float: right;
		margin-top: -5px;
		html[dir="rtl"] & {
			float: left;
			direction: ltr;
		}
		[class*="swiper-c"] + [class*="swiper-button"] {
			margin-left: 5px;
		}
		.swiper-button-next,
		.swiper-button-prev {
			position: static;
			margin-top: 0;
			display: inline-block;
			width: 30px;
			height: 30px;
			border: 1px solid $swiperButton;
			@include border-radius(3px);
			background-image: none;
			text-align: center;
			font-size: 14px;
			line-height: 28px;
			color: $black;
			background: $productBtnBg;
			@include transition(background .3s ease, color .3s ease);
			&:hover {
				color: $primary;
			}
			&.swiper-button-disabled {
				background: transparent;
				color: $navBorder;
				border-color: $navBorder;
				opacity: 0;
			}
			&:before {
				font-family: 'Linearicons';
			}
		}
		.swiper-button-next {
			&:before {
				content: '\ec3c';
			}
		}
		.swiper-button-prev {
			&:before {
				content: '\ec3b';
			}
		}
	}
	&.featured, &.bestseller, &.latest, &.specials, &.single-category, &.blog_articles, &.manufacturer {
		.box-heading {
			h2, h3, h4, h5, h6 {
				display: inline-block;
				margin-top: 4px;
				aside & {
					display: block;
					text-align: center;
				}
			}
			@media (max-width: 1199px) {
				.row {
					display: flex;
					flex-flow: row wrap;
					[class*="col-"] {
						&:nth-child(1) {
							order: 1;
							flex: 1 50%;
						}
						&:nth-child(2) {
							order: 3;
							flex: 1 100%;
							margin-top: 5px;
						}
						&:nth-child(3) {
							order: 2;
							flex: 1 50%;
						}
					}
				}
			}
			@media (max-width: 767px) {
				.row {
					[class*="col-"] {
						li {
							+ li {
								margin-left: 15px;
								[dir="rtl"] & {
									margin-left: 0;
									margin-right: 15px;
								}
							}
						}
					}
				}
			}
			@media (max-width: 479px) {
				.row {
					[class*="col-"] {
						&:nth-child(2) {
							li {
								display: block;
								margin-left: 0;
								margin-right: 0;
								margin-top: 10px;
								+ li {
									margin-left: 0;
									[dir="rtl"] & {
										margin-left: 0;
										margin-right: 0;
									}
								}
							}
						}
					}
				}
			}
			.align-right {
				text-align:right;
				html[dir="rtl"] & {
					text-align:left;
				}
				.nav-tabs {
					width:auto;
				}
			}
		}
	}
	&.manufacturer {
		.swiper-wrapper {
			display: flex;
			align-items: center;
			.swiper-slide {
				text-align: center;
			}
		}
	}	
	.nav-tabs {
		border-bottom: none;
		margin-top: 13px;
		margin-bottom: 4px;
		vertical-align:top;
		text-align: center;
		width: 100%;
		> li {
			display: inline-block;
			float: none;
			+ li {
				margin-left: 60px;				
				html[dir="rtl"] & {
					margin-left: 0;
					margin-right: 60px;
					
				}
			}
			> a {
				padding: 0;
				font-size: 14px;
				line-height: 14px;
				font-weight: 700;
				@include border-radius(0px);
				border: none;
				margin-right: 0;
				color: $textPrimary;
				&:focus,
				&:hover {
					background-color: transparent;
				}
				&:hover {
					border-color: transparent;
					color: $primary;
				}
			}
			&.active {
				> a {
					&,
					&:focus,
					&:hover {
						background: transparent;
						border: none;
						color: $primary;
					}
				}
			}
		}
	}
}

aside {
	[class*="col-"],.container {
		width: 100%;
		padding: 0;
	}
	.row {
		margin: 0;
	}
}

/*========================================================
                    Responsive Box
=========================================================*/

@media (max-width: 1199px) {
	.top .box .box-heading {
		background: none;
		
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.box .nav-tabs > li + li {
		margin-left: 40px;
		[dir="rtl"] & {
			margin-left: 0;
			margin-right: 40px;
		}
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.box .nav-tabs > li + li {
		margin-left: 40px;
		[dir="rtl"] & {
			margin-left: 0;
			margin-right: 40px;
		}
	}
	.box.info .box-content ul a {
		padding: 13px 5px;
	}
	aside .box .box-heading h3 {
		font-size: 16px;
	}
	.social-list li + li {
		margin-left: 1px;
	}
	.box.manufacturer {
		margin-bottom: 10px;
		.box-heading {
			margin-bottom: 20px;
		}
	}
}

@media (max-width: 767px) {
	.box .box-heading h2, .box .box-heading h3 {
		font-size: 16px;
		line-height: 1.2em;
	}
	aside .box .box-heading {
		position: relative;
	}
}

@media (min-width: 550px) and (max-width: 767px) {
	aside .box .product-thumb{
		width: 60%;
		margin: 0 auto 30px;
	}
}

@media (max-width: 480px) {
	.box.featured, .box.bestseller, .box.latest, .box.specials, .box.single-category, .box.blog_articles, .box.manufacturer,
	.box {
		.box-heading h3, .box-heading h2 {
			font-size: 14px;
			text-align: left;
		}
		.box-heading .align-right {
			text-align:center;
		}
	}
	.box {
		margin-bottom: 20px;
	}
}
