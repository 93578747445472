
.box-info {
	text-align:center;
	padding: 77px 0px 35px 0;
	h1,h2,h3,h4,h5,h6 {
		color:$white;
	}
	
}
.html-banner{
	padding-top: 57px;
	padding-bottom: 42px;
	.html-title{
		font-size: 30px;
		color: $primary;
		line-height: 31px;
		letter-spacing: -0.3px;
	}
	p{
		font-family: 'Georgia';
		font-size: 18px;
		font-style: italic;
		margin-top: 7px;
		line-height: 31px;
		color: #e47a73;
	}
}
.box-info__item {	
	h3 {
		font-size:24px;
		line-height:1.2em;
		text-transform:uppercase;
		letter-spacing: .5px;
		margin-bottom:8px;
		color: #333333;
		@media (max-width: 1199px) {
			font-size: 18px;
		}
		@media (max-width: 768px) {
			font-size: 13px !important;
		}
	}
	p {
		font-size:18px;
		line-height:1.4em;
		color: #333333;
		letter-spacing: -0.5px;
		margin-top: 19px;
		font-weight: 600;
		@media (max-width: 1199px) {
			font-size: 15px;
		}
		@media (max-width: 768px) {
			font-size: 13px;
		}
	}
}
#common-home{
	position: relative;
	&:after{
		content: '';
		position: absolute;
		bottom: 0;
		left: 10px;
		
		width: 100%;
		height: 1px;
		background: #cccccc;
	}
}

/*========================================================
                   Responsive Banners
=========================================================*/

@media (min-width: 768px) and (max-width: 1479px) {
	.box-info {
		padding-top: 46px;
		padding-bottom: 40px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.box-info__item h3 {
		font-size: 16px;
		letter-spacing: 0;
		margin-bottom: 4px;
	}
}

@media (max-width: 767px) {
	.box-info__item {
		margin-bottom: 30px;
	}
}

@media (max-width: 767px) {
	.box-info {
		padding-top: 30px;
		padding-bottom: 25px;
	}
}
