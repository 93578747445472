html {background-color: #2e2e2e;}
.wrapper {position:fixed;top:50%;left:50%;margin-left:-55px;margin-top:-50px;}

@for $i from 1 through 71 {
  $deg:5*$i;
  .wrapper div:nth-child(#{$i}) {
    transform:rotate(#{$deg}deg);
  }
}
.wrapper div {
  position:absolute;
  height:50px;
  width:50px;
  transform-origin:100% 50%;
}
.wrapper div .bar {
  position:absolute;
  height:1px;
  width:100px;
  background-color:#369;
  left:0;
  border-radius:20px;
  transform-origin:right;
  animation: spin 6s linear infinite;
}

@-webkit-keyframes spin {
  0%   {
    transform:rotate(0deg);
  }
  100% {
    transform:rotate(360deg);
  }
}
#page-preloader {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: $white;
	z-index: -1;
	opacity: 0;
	@include transition(all 1.3s ease .3s);
	&.visible {
		opacity: 1;
		z-index: 100500;
		.preloader {
			opacity: 1;
			@include transform(translate(-50%, -50%));
		}
	}
}