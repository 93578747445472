/* ===================   Product Quickview   =================== */
.ajax-quickview,
.ajax-quickview-images,
.ajax-quickview-images > ul,
.ajax-quickview-images > ul > li {
	height: 100%;
}

.ajax-quickview-images,
.ajax-quickview-cont-wrap {
	width: 50%;
}

.ajax-quickview {
	.name, .cart-button {
		margin-top: 15px;
	}
	.name {
		h3 {
			font-size: 40px;
			line-height: 40px;
			font-weight: 600;
		}
	}
}

.ajax-quickview-images {
	position: relative;
	float: left;
	> ul {
		margin: 0;
		> li {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			z-index: 1;
			opacity: 0;
			text-align: center;
			overflow: hidden;
			@include transition(opacity .5s ease);
			> img {
				display: inline-block;
				position: absolute;
				top: 50%;
				left: 50%;
				@include transform(translate(-50%, -50%));
				max-height: 101%;
				max-width: none;
				width: auto;
			}
			&.active {
				z-index: 2;
				opacity: 1;
			}
		}
	}
	.next-img,
	.prev-img {
		position: absolute;
		top: 50%;
		@include transform(translateY(-50%));
		z-index: 5;
	}
	.next-img {
		right: 15px;
	}
	.prev-img {
		left: 15px;
	}
}

.ajax-quickview-cont-wrap {
	float: right;
	max-height: 100%;
	overflow-y: auto;
	.form-horizontal .form-group {
		margin-left: 0;
		margin-right: 0;
	}
	.ajax-quickview-cont {
		padding: 50px;
	}
	.sale, .new-pr, .discount {
		position: static;
	}
	.product-options {
		border-bottom: none;
	}
	btn-primary {
		vertical-align: middle;
	}
	.counter, input[name*="quantity"] {
		vertical-align:middle;
	}
	.counter-plus {
		margin-right:10px;
		[dir="rtl"] & {
			margin-right:0;
			margin-left:10px;
		}
	}
}

.ajax-quickview-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 20;
	background: rgba($textPrimary, .3);
	opacity: 0;
	visibility: hidden;
	.ajax-quickview-overlay__preloader {
		position: absolute;
		top: 50%;
		left: 50%;
		@include transform(translate(-50%, -50%));
		@include border-radius(50%);
		display: inline-block;
		width: 120px;
		height: 120px;
		border: 5px solid $white;
		background: url(../image/preloader.gif) no-repeat 50% 50% $white;
	}
	&.visible {
		opacity: 1;
		visibility: visible;
	}
}

.fancybox-skin:hover .fancybox-nav span {
	visibility: visible;
}

.fancybox-nav {
	height: 40px;
	top: 50%;
	width: 40px
}

.fancybox-next {
	right: -60px;
}

.fancybox-prev {
	left: -60px;
}

@media (min-width: 768px) and (max-width: 991px) {
	.ajax-quickview .name h3 {
		font-size: 32px;
		line-height: 1em;
	}
	.ajax-quickview-cont-wrap .ajax-quickview-cont {
		padding: 40px 15px;
	}
}