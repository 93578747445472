//--------------------------------------------------------------
//                       Floated menu
//--------------------------------------------------------------
$item-margin: 35px;

.sf-menu{
	@include clearfix;
	> li {
		position: relative;
		float: left;		
		& + li {
			margin-left: $item-margin;
		}
		html[dir="rtl"] & {
			float: right;
			& + li {
				margin-left: 0;
				margin-right: $item-margin;
			}
		}
	}
}

@media (min-width: 1200px) and (max-width: 1479px) {
	.sf-menu > li + li {
		margin-left: 20px;
		html[dir="rtl"] & {
			margin-left: 0;
			margin-right: 20px;
		}
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.sf-menu > li + li {
		margin-left: 15px;
		html[dir="rtl"] & {
			margin-left: 0;
			margin-right: 15px;
		}
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	ul.sf-menu > li {
		+ li {
			margin-left: 20px;
			html[dir="rtl"] & {
				margin-left: 0;
				margin-right: 7px;
			}
		}
	}
}