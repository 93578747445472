/*================ Box Cart  ===============*/
$close-button-color: $primary;

.box-cart {
	.btn-remove {
		&-wrap, &__cnt {
			position: absolute;
			left: 0;
			right: 0;
			i {
				font-size: 20px;
			}
		}
		&-wrap {
			top: 0;
			bottom: 0;
			z-index: 10;			
			display: none;
			background: $dropdown-bg;
		}
		&__cnt {
			top: 50%;
			@include transform(translateY(-50%));
			.link{
				display: inline-block;
				margin: 0 13px;
				color: $textPrimary;				
			}
		}
	}
	.btn-danger,.btn-danger:focus {
		border: 0;
		padding: 0;
		color: $close-button-color;
		font-size: 15px;
		background: none;
		&:hover {
			color: $primary;
			background: none;
		}
		&:focus {
			box-shadow: none;
		}
	}
	a:hover img {
		opacity: .6;
	}
	.fl-left {
		float: left;
		[dir="rtl"] & {
			float: right;
		}
		width: 25%;
	}
	.fl-right {
		float: right;
		width: 62%;
		margin-right: 7%;
		[dir="rtl"] & {
			float: left;
			margin-right: 0;
			margin-left: 7%;
		}
		line-height: 28px;
	}	
	.btn-remove {
		position: absolute;
		right: 8px;
		[dir="rtl"] & {
			right: auto;
			left: 8px;
		}
		top: 11px;
		line-height: 20px;
		color: $close-button-color;
		i {
			font-size: 18px;
			line-height: 20px;
			@include transition(.4s all ease);
		}
		
	}
	.buttons .link + .link {
		margin-left: 10px;
		[dir="rtl"] & {
			margin-left: 0;
			margin-right: 10px;
		}
	}
}

/*================  Cart  ===============*/

.cart {
	position: relative;
	.bold {
		font-weight: 800;
	}
	> button {
		position: relative;
		text-align: right;
		[dir="rtl"] & {
			text-align: left;
		}
		font-family:$baseFontFamily;
		i, strong, span{
			vertical-align: middle;
			font-size: 14px;
		}
		.cart-total {
			
		}
		.cart-total2 {
			display:none;
			display:inline-block;
			position:absolute;       
			top:-15px;
			left:15px;
			[dir="rtl"] & {
				left:auto;
				right:15px;
			}
			color:$white;
			background:$primary;
			@include border-radius(50%);
			width:24px;
			height:24px;
			line-height:24px;
			text-align:center;
		}
		strong {
			
				color: $black;
				
			
			@include transition(all 0.3s ease);
		}
		i {
			float: left;			
			position: relative;
			margin-right: 10px;
			[dir="rtl"] & {
				float: right;
				margin-right: 0;
				margin-left: 10px;
			}
			font-size: 24px;
			font-weight: 400;
			color:$black;
			@include transition(all 0.3s ease);
		}
		strong {
			
		}
		span {
			font-weight: 400;			
			b {
				display: none;
				line-height: 14px;
				font-weight: 400;
			}
		}
		&.active,
		&:hover {
			&, strong, i {
				color:$primary;
			}
		}
	}
	.cart-remove-btn{
		margin-left: 5px;
		&:before, > span {
			vertical-align: middle;
		}
		&:before{
			margin-right: 5px;
		}
	}
	.btn {
		&, &:visited {
			color: $white;
			background: $primary;
			border-color:$primary;
		}
		&:hover,
		&:focus,
		&:active	{
			color: $white;
			background: #333;
			border-color: #333;
		}
		i {
			font-size: 16px;
			margin-left:5px;
			display:inline-block;
		}
	}
	.btn-l,
	.btn-r{
		display: block;
		margin-bottom: 10px;
		font-size: 10px;
	}
	.btn-l {
		&, &:visited {
			color: $white;
			background-color: #555;
			border-color:#555;
		}
		&:hover,
		&:focus,
		&:active	{
			color: $white;
			background-color: #333;
			border-color: #333;
		}
	}
	.name {
		margin-bottom: 3px;
		a {
			text-transform: none;
			line-height: 19px;
			font-size: 12px;
			font-weight: 500;
		}
	}
	.quant {
		background: #eee;
		padding: 3px 12px;
		margin: 0 10px 0 0;
		font-size: 16px;
		color: $baseColor;
	}
	.price-cart {
		color: $white;
		font-weight: bold;
		font-size: 14px;
		margin-left: 6px;
	}
}

/*================  Cart DropDown ===============*/

.box-cart .toggle_cont {
	z-index: 1001;
	min-width: 260px;
	width: 320px;
	padding: 20px;
	max-height: 434px;
	right: 0;
	left: auto;
	top: 100%;
	list-style-type: none;
	color: #aaa;
	margin: 10px 0 0;
	border: 0;
	overflow-y: auto;
	overflow-x: hidden;
	text-align: left;
	background: rgba(#000, 0.87);
	[dir="rtl"] & {
		text-align: right;
	}
	.link:hover, .total .text-right {
		color: $white;
	}
	.name a {
		&, &:visited, &:focus {
			color: $white;
		}
		&:hover {
			color: $primary;
		}
	}
	li {
		position: relative;
		&.product {
		 padding-bottom: 15px;
			.name {
				width: 165px;
				padding-left: 20px;
				[dir="rtl"] & {
					padding-left: 0;
					padding-right: 20px;
				}
			}
			.image {
				width: 70px;
			}
		}
	}
	p {
		margin: 0;
		font-size: 11px;
		text-transform: uppercase;
		letter-spacing: .3px;
		font-weight: 500;
	}
	> li {
		position: relative;
		overflow: hidden;
		& + li {
			border-top: 1px solid rgba(0,0,0,.08);
		}
	}
	.btn {
		padding: 10px 10px;
		font-size: 14px;
	}
	.total {
		list-style-type: none;
		padding: 10px 0;
		> .t-row {
			position: relative;
			overflow: hidden;
			> div {
				width: 60%;
				float: left;
				[dir="rtl"] & {
					float: right;
				}
				line-height: 28px;
				vertical-align: middle;
				b{
					font-weight: 500;
					color: $textPrimary;
				}
				& + div{
					width: 40%;
				}
				em {
					color: $textPrimary;
					font-style: normal;
					font-weight: 600;
				}
			}
		}
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.cart > button i {
		margin-right: 2px;
	}
	.cart > button strong {
		display:none;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.cart > button {
		i {
			margin-right: 0;
		}
		strong, span {
			display:none;
		}
	}
}

@media (max-width: 767px) {
	.cart {
		position: fixed;
		top: 0;
		margin: 0;
		z-index: 10000;
		> button {
			position: relative;
			.cart-total, strong{
				display: none;
			}
			.cart-total2 {
				top: 5px;
				background:$primary;
			}
			i {
				line-height:inherit;
				color:#000 !important;
				margin-right: 15px;
			}
		}
		.toggle_cont {
			max-height: 260px;
			overflow: auto;
			background:$black;
			right: -10px;
			margin: 0;
			overflow-y: auto;
			overflow-x: hidden;
		}
	}
}

@media (max-width: 480px) {
	.cart .toggle_cont {
		right: -40px;
		width: 290px;
		line-height: 1.2em;
		padding: 10px 15px;
		.total {
			font-size: 12px;
			> .t-row > div {
				line-height: 1.7em;
			}
		}
		li.product .image {
			width: 40px;
		}
		.buttons {
			margin: .7em 0;
		}
	}
}
