//--------------------------------------------------------------
//                      Slideshow
//--------------------------------------------------------------
@function removeHash($color) {
	@if type-of($color) == color {
		$color: "\"" + $color + "\"";
		@if str-index($color, '#') == 1 {
			$color: str-slice($color, 2);
		}
	}
	@return $color;
}

$sliderAC: removeHash($sliderArrowColor);

.swiper-button-prev, .swiper-container-rtl .swiper-button-next,
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
	background-image: none !important;
}

.swiper-slider {
	&.swiper-container {
		overflow:visible;
	}
	
	
	html[dir="rtl"] & { 
		direction: ltr;
	}
	.swiper-wrapper {
		overflow:hidden;
	}
	.slide-desc {
		position: absolute;
		left: 0;
		right: 0;
		transform: translateY(-50%);
		text-align: left;
		html[dir="rtl"] & { 
			direction: rtl;
		}
		h2, h3, h4, h5, h6, p {
			margin: 0;
			column-rule: $black;
		}
		h2 {
			font-family: 'Georgia';
			font-size: 72px;
			line-height: 72px;
			color: $white;
			font-weight: 400;
			text-transform: uppercase;			
			font-style: italic;
			@media (max-width: 768px) {
				font-size: 30px !important;
				line-height: 38px;
			}
		}
		
		
		.btn-primary {
			font-family: 'Georgia';
			margin-top:37px;
			padding: 0;
			color: $primary;
			position: relative;
			background: transparent;
			font-style: italic;
			font-size: 18px;
			font-weight: 400;
			&:hover{
				color: $white;
				&:after{
					background: $white;
				}
			}
			&:after{
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 1px;
				background: $primary;
			}
		}
		display: inline-block;
		position: absolute;
		top: 53%;
		left:0;
		@include transform(translateY(-50%) );
	}
	.swiper-button-next, .swiper-button-prev {
		width:30px;
		margin-top:-12px;
		text-align:center;
	}	
	.swiper-button-next, 
	.swiper-container-rtl .swiper-button-prev {
		background-image: none;
		opacity: 1;
		font-size: 30px;
		line-height:30px;
		height:30px;
		right:18px;
		@media (min-width: 1480px) {
			right:96px;
		}
		&:before {
			position:absolute;
			top:0;
			left:0;
			width:inherit;
			content:'\ec3c';
			font-family: 'Linearicons' !important;
			color:$white;
			@include transition(0.3s all ease);
		}
		&:hover:before {
			color:$primary;
		}
	}
	.swiper-button-prev, 
	.swiper-container-rtl .swiper-button-next {
		background-image: none;
		font-size: 30px;
		line-height:30px;
		height:30px;
		left:18px;
		@media (min-width: 1480px) {
			left:96px;
		}
		&:before {
			position:absolute;
			top:0;
			left:0;
			width:inherit;
			content:'\ec3b';
			font-family: 'Linearicons' !important;
			color:$white;
			@include transition(0.3s all ease);
		}
		opacity: 1;
		&:hover:before {
			color:$primary;
		}
	}
	&.swiper-container-rtl {
		[class] & {
			.swiper-button-prev {
				&:before {
					content:'\ec3c';
				}
			}
			.swiper-button-next {
				&:before {
					content:'\ec3b';
				}
			}
		}
	}
	.swiper-pagination-bullet {
		width: 11px;
		height: 11px;
		background:rgba(#fff,.6);
		opacity:1;
		@media (min-width: 768px) {
			@include transition(.3s all ease);
		}
	}
	.swiper-pagination-bullet:hover, 
	.swiper-pagination-bullet-active {
		background-color: $white;
	}
	&.swiper-container-horizontal>.swiper-pagination-bullets, 
	.swiper-pagination-custom, .swiper-pagination-fraction {
		position:absolute;
		top:auto;
		bottom:4%;
		left:50%;
		@include transform(translateX(-50%));
		display:inline-block;
		width:auto;
	}
}

/*---------------------------------------------*/


@media (max-width: 1730px) {
	.swiper-slider .swiper-button-next, .swiper-slider .swiper-container-rtl .swiper-button-prev {
		right: 0;
	}
	.swiper-slider .swiper-button-prev, .swiper-slider .swiper-container-rtl .swiper-button-next {
		left: 0;
	}
	.slide-desc .container {
		padding-left:25px;
		padding-right:25px;
	}
}


@media (min-width: 1200px) and (max-width: 1479px) {
	.swiper-slider {
		height: 605px !important;
		.slide-desc {
			h2 {
				font-size: 64px;
				margin-bottom:8px;
			}
			h3 {
				font-size: 64px;
				margin-bottom:10px;
			}		
			h5 {
				font-size: 18px;
				margin-bottom:8px;
			}
			.btn-primary {
				margin-top:22px;
			}
		}
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.swiper-slider {
		height: 480px !important;
		.slide-desc {
			h2 {
				font-size: 50px;
				margin-bottom:6px;
			}
			h3 {
				font-size: 50px;
				margin-bottom:8px;
			}		
			h5 {
				font-size: 16px;
				margin-bottom:6px;
			}
			.btn-primary {
				margin-top:18px;
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.swiper-slider {
		height: 420px !important;
		.slide-desc {
			top: 60%;
			h2 {
				font-size: 40px;
				margin-bottom:4px;
			}
			h3 {
				font-size: 40px;
				margin-bottom:6px;
			}		
			h5 {
				font-size: 16px;
				margin-bottom:4px;
			}
			.btn-primary {
				margin-top:16px;
			}
		}
		.swiper-button-prev, 
		.swiper-container-rtl .swiper-button-next,
		.swiper-button-next, 
		.swiper-container-rtl .swiper-button-prev {
			font-size: 30px;
			line-height: 30px;
		}
	}
}

@media (max-width: 767px) {
	.swiper-slider {
		height: 300px !important;
		.slide-desc {
			@include transform(none);
			top:25px;
			bottom:40px;
			left:30px;
			right:30px;
			h2 {
				font-size: 40px;
				margin-bottom:4px;
			}
			h3 {
				font-size: 40px;
				margin-bottom:6px;
			}		
			h5 {
				font-size: 16px;
				margin-bottom:4px;
			}
			.btn-primary {
				margin-top:16px;
			}
		}
		&.swiper-container-horizontal > .swiper-pagination-bullets, 
		.swiper-pagination-custom, 
		.swiper-pagination-fraction {
			bottom:10px;
		}
		.swiper-button-next, .swiper-button-prev {
			height: 20px;
		}
		.swiper-button-next, .swiper-container-rtl .swiper-button-prev,
		.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
			width:20px;
			font-size: 20px;			
			line-height: 20px;
		}
		.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
			right: 5px;
		}
		.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
			left: 5px;
		}
		.swiper-pagination-bullets {
			padding-top: 20px;
		}
	}
	.slide-desc .container {
		padding-left: 0;
		padding-right: 0;
	}
}

@media (max-width: 480px) {
	.swiper-slider {
		height: 200px !important;
		.slide-desc {
			h2 {
				font-size: 26px !important;
			}
			h3 {
				font-size: 16px;
				margin-bottom:4px;
			}		
			h5 {
				font-size: 14px;
			}
			.btn-primary {
				margin-top:10px;
				padding: 7px 15px;
			}
		}
		.swiper-button-next, 
		.swiper-container-rtl .swiper-button-prev, 
		.swiper-button-prev, 
		.swiper-container-rtl .swiper-button-next {
			width: 16px;
			font-size: 16px;
			line-height: 16px;
		}
	}
}