/*========================================================
                      Product
=========================================================*/

/* ===================   Product Layout   =================== */
.product-layout {
	margin-bottom: 32px;
	.rating{
		display: none;
	}
}

.product-thumb {
	&:hover{
		.image > .quickview{
			opacity: 1;
		}
	}
	.caption + .button-group {
			margin-top: 12px; 
	}
	.button-group .product-btn {
		margin-left:4px;
		margin-right:4px;
	}
}

/* ===================   Product Image   =================== */
.product-thumb .image {
	position: relative;
	overflow: hidden;
	background: $white;

	> a {
		display: block;
		img {
			@include transition(all .5s ease);
			margin: 0 auto;
		}
	}
	.img-primary,
	.img-secondary {
		opacity: 0;
	}
	.button-wrap {
		position:absolute;
		top:50%;
		left:50%;
		display:inline-block;
		margin-bottom:0;
		@include transform(translateX(-50%) translateY(-50%));
		white-space:nowrap;
		@media (min-width: 768px) {
			opacity:0;
			@include transform(rotateY(180deg) translate(50%, -100%) scale(.2));
			@include transition(all .5s ease);
			.product-btn {
				@include transform(translateX(100%));
				@include transition(all .7s ease);
				+ .product-btn {
					@include transform(none);
					+ .product-btn {
						@include transform(translateX(-100%));
					}
				}
			}
		}
	}
	.rating {
		margin: 0;
		position:absolute;
		bottom:10px;
		left:50%;
		margin-left:-36px;
	}
	.lazy-loaded{
		display: block;
		.img-primary{
			opacity: 1;
		}
		.img-secondary {
			opacity: 0;
		}
	}
	&:hover {		
		.lazy-loaded{
			.img-primary {
				opacity: 0;
			}
			.img-secondary {
				opacity: .4;
			}
		}
	}
}

/* ===================   Product Caption   =================== */
.name > a {
	&, &:visited, &:focus {
		color: $textPrimary;
	}	
	&:hover {
		color: $primary;
	}
}

.product-thumb {
	position: relative;
	@include box-sizing(border-box);
	.aside {
		display: none;
	}
	.rating {
		margin-top:6px;
		margin-bottom:9px;
		line-height:1.2em;
		> span {
			margin-left:2px;
			margin-right:2px;
		}
	}
	.name {
		font-size: 14px;
		line-height: 23px;
		font-weight:600;
		margin: 0;
		margin-bottom: 4px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		> a {
			display: inline;
		}
	}
	.content {
		
		.caption{
			margin-bottom: 11px;
		}
	}
	.description {
		display: none;
	}
	.btn-icon {
		margin-left:6px;
		margin-right:6px;
	}
	.btn-link {
		background: $primary;
		padding: 15px 12px;
		display:inline-block;
		font-size:12px;
		line-height:1.2em;
		color: $white !important;
		letter-spacing: 1px;
		text-transform: uppercase;
		font-weight:400;
		text-decoration:none !important;
		position:relative;
		&:hover{
			color: $black !important;
		}
		
		
		@include transition(.5s all ease);
		i {
			font-size:20px;
		}		
		&, &:visited, &:focus {
			color: $black;
		}
		&:hover{
			color: $primary;
		}
		@media (min-width: 768px) {
			&:hover {
				color: $primary;				
				&:before {
					background-color: $primary;
					left:50%;
					width:0;
				}
			}
		}
		@media (max-width: 767px) {
			span {
				display:none;
			}
		}
	}
	@media (min-width: 768px) {
		&:hover {
			.image .button-wrap {
				opacity:1;
				@include transform(translateX(-50%) translateY(-50%));
				.product-btn, .product-btn + .product-btn, .product-btn + .product-btn + .product-btn {
					@include transform(none);
				}
			}
		}
	}
}

.cart-button .btn-icon {
	margin-left:5px;
	margin-right:5px;
}

/* ==================    Product List, Product Grid    =================== */
@media (min-width: 768px)  {
	.product-list .product-thumb {
		.caption, .cart-button {
			overflow: hidden;
		}
	}
}

.row .product-list {
	padding-right: 20px;
    padding-left: 20px;
}

.button-wrap {
	margin-bottom: 18px;
	button+button{
		margin-left: 5px;
	}
}

@media (min-width: 768px)  {
	.block-list {
		display:none;
	}
	.product-list {
		.product-thumb {
			text-align: left;
			@include clearfix();
			margin-bottom: 30px;
			.image, .content {
				float: left;
			}
			.image {
				width: 30.77%;
			}
			.description {
				display: block;
			}
			.content {
				padding: 0 40px;
				width: 66%;
				text-align: left;
			}
			.image .button-wrap {
				display:none;
			}
		}
		.block-list {
			display:block;
		}
	}
}

@media (min-width: 992px) {	
	.product-layout.product-grid.col-lg-3:nth-child(4n+5) {
		clear:left;
		[dir="rtl"] & {
			clear:right;
		}
	} 
	.product-layout.product-grid.col-lg-4:nth-child(3n+4) {
		clear:left;
		[dir="rtl"] & {
			clear:right;
		}
	} 
}

@media (max-width: 991px) {	
	.product-layout.product-grid.col-lg-3:nth-child(2n+3),
	.product-layout.product-grid.col-lg-4:nth-child(2n+3) {
		clear:left;
		[dir="rtl"] & {
			clear:right;
		}
	}
}

/* ==================   SALE   =================== */
.sale, .discount, .new-pr {
	display: inline-block;
	position: absolute;
	z-index:1;
	font-size: 12px;
	line-height:1.2em;
}

.sale, .new-pr {
	top: 0;
	padding:17px 2px;
	width:44px;
	color:$white;
	text-align:center;
	background:$black;
	&:before, &:after {
		position:absolute;
		width: 0;
		height: 0;
		top:100%;
		content:'';
		display:inline-block;
	}
	&:before {		
		border-top: 11px solid $black; 
		border-right: 23px solid transparent;
		left:0;
	}
	&:after {
		border-top: 11px solid $black; 
		border-left: 23px solid transparent;
		right:0;
	}
}

.sale {
	background-color: $sale;
	right: 19px;
	&:before {		
		border-top: 11px solid $sale; 
	}
	&:after {
		border-top: 11px solid $sale;
	}
}

.new-pr {
	left:19px;
}

.discount {
	color: $sale;
	top: 15px;
	right: 65px;
}
.block-price{
	margin-bottom: 8px;
	.price{
		display: block;
		text-align: center;
	}
}

/* ===================   Product Price    =================== */
.product-thumb, .ajax-quickview {
	.price-new, .price-old, .price {
		font-size: 14px;
		line-height: 20px;

		@media (max-width: 1199px) {
			font-size: 15px;
		}
	}
	.price, .price-new {
		font-weight: 700;
	}
	.price {
		color: $black;
		margin: 0;
	}
	.price-new {
		color: $black;
	}
	.price-old {
		color: $priceOld;
		font-size: 14px;
		line-height: 23px;
		font-weight: 400;
	}
}

.price-new, .price-old, .price {
	display: inline-block;
	font-size: 18px;
	line-height: $baseLineHeight;
	color: $textPrimary;
}

.price-new {
	margin-right:5px;
}

.price-old {
	font-weight: 300;
	text-decoration: line-through;
	vertical-align: top;
}

.price-section {
	margin-top: 20px;
	font-weight: 700;
	.price-new, .price-section .price-old {
		font-size: 30px;
		color: $textPrimary;
		line-height: 30px;
	}
	.price-old {
		font-size: 20px;
		line-height: 30px;
	}
	.reward {
		display: block;
		margin-top: 10px;
	}
}

.price-tax {
	font-size: 12px;
}

.price-total {
	font-weight: bold;
}

.price s {
	font-size: 14px;
	color:$baseColor;
}

.stock, .out-stock {
	font-weight: 700;
}

.stock {
	color: #54B70B;
}

.out-stock {
	color: #EE372A;
}

.product-thumb .price-tax {
	color: #999;
	font-size: 12px;
	display: block;
}

/* ===================   Product Rating   =================== */
.fa-stack {
	font-size: 18px;
	line-height: 14px;
	width: 0.9em;
	height: 14px;
	i {
		color: $rating;
		&.star {
			color: $ratingStar;
		}
	}
}

.fa-stack-2x {
	font-size: 1em;
}

.product-thumb .fa-stack {
	font-size: 12px;
}

.rat {
	color: $primary;
}

.product-thumb .product-options {
	display: none;
}

.ajax-overlay {
	.product-options {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		max-width: 450px;
		min-width: 300px;
		padding-bottom: 0;
		border-bottom: none;
		max-height: 80%;
		backface-visibility: hidden;
		h3 {
			margin-top: 0;
		}
		.col-sm-8 {
			text-align: left;
		}
		.options {
			padding: 20px;
			text-align: center;
			background: #FFF;
			@include border-radius(15px);
			@include box-shadow(1px 1px 8px rgba(#000, .8));
		}

		.ajax-overlay_close {
			position: absolute;
			right: 10px;
			html[dir="rtl"] & {
				right: auto;
				left: 10px;
			}
			top: 10px;
			font-size: 16px;
			color: rgba(#000, .5);
			font-family: $material;
			text-shadow: 1px 1px 5px rgba(#fff, .2);
			&:before {
				content: '\e054';
			}
			&:hover {
				color: $black;
			}
		}
	}
	.product-option-wrap {
		text-align: center;
		height: 100%;
		&:before {
			content: '';
			display: inline-block;
			height: 100%;
			vertical-align: middle;
		}
	}

}

.img-category {
	margin-bottom:15px;
}

.ajax-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	visibility: hidden;
	opacity: 0;
	@include transition(opacity .3s ease-in-out);
	z-index: 997;
	&.visible {
		opacity: 1;
		background: rgba(#000, .7);
		visibility: visible;
	}
}

.review-text {
	font-size:12px;
	color:$primary;
}

#nav_related {
	margin-top:-20px;
}

/*========================================================
               Responsive Styles Product
=========================================================*/

@media (min-width: 768px) {
	.box .box-content .last {
		clear: left;
	}
	.fancybox-next {
		right: -30px;
	}
	.fancybox-prev {
		left: -30px;
	}
	.product-reviews .fa-stack {
		font-size: 14px;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	aside {
		.product-thumb .btn-link {
			i {
				display: inline-block !important;
			}
			span {
				display: none !important;
			}
		}
	}
}

@media (max-width: 767px) {
	.box .product-layout,
	.box.related-block,
	.product-layout.product-grid,
	.product-layout.product-list {
		text-align:center;
		.product-thumb {
			width:318px;
			max-width:100%;
			display: inline-block;
		}
	}
	.product-thumb {
		.image .button-wrap {
			display:none;
		}
		.content .caption {
			text-align:center;
		}
	}
	.img-category {
		text-align:center;
	}
	#content .product-layout {
		width: 50%;
		float: left;
		&:nth-child(2n+1) {
			clear: left;
		}
		+ .product-layout {
			margin-top: 0;
		}
		&.clr{
			clear: both;
		}
	}
	.product-thumb .content {
		padding-top: 10px;
	}
	.button-wrap {
		margin-bottom: 15px;
	}
	.product-list,
	.product-grid {
		.product-thumb {
			.image, .caption, .aside {
				float: none;
				margin: 0 0 15px;
				width: 100%;
			}
			.aside {
				display: none;
			}
			.caption {
				border-right: none;
			}
			> .price, .cart-button {
				display: block;
			}
		}
		+ .product-list .product-thumb {
			border-top: none;
			padding-top: 0;
		}
	}

	.clearfix + .product-list .product-thumb {
		border-top: none;
		padding: 0;
	}	
	.product-layout {
		margin-bottom: 35px;
	}
}

@media (max-width: 540px) {
	#content .product-layout {
		width: 100%;
		float: none;
	}
	.product-thumb .product-options {
		max-width: 95%;
		[class*="col-xs"] {
			float: left;
			+ [class*="col-xs"] {
				margin-top: 0 !important;
			}
		}
	}
}

@media (max-width: 480px) {
	#nav_related {
		margin-top: -25px;
	}
}
