/*========================================================
                      Blog Styles
=========================================================*/
.blog_article {
	margin-bottom: 40px;
	.btn {
		margin-top: 30px;
	}
	+ .blog_article {
		padding-top: 40px;
		border-top: 1px solid $borderColor;
	}
}

.article-info {
	.article-sub-title {
		text-align: center;
	}
	.btn {
		margin-top: 20px;
	}
	h5,h6 {
		margin-bottom:10px;
		text-transform:uppercase;
	}
	p + {
		h5,h6 {
			margin-top:25px;
		}
	}
}

.article-image {
	text-align: center;
	overflow:hidden;
}

.simple_blog-article-view h1:after {
	margin-bottom: 15px;
}

.article-title {
	font-size: 22px;
	line-height: 22px;
	font-weight: 700;
	> a {
		display: inline;
	}
	a, a:visited, a:focus {
		color:$textPrimary;
	}
	a:hover {
		color:$primary;
	}
	+ * {
		margin-top: 15px;
	}
}

* + .article-description {
	margin-top: 16px;
}

.article-sub-title {
	font-size: 12px;
	line-height: 12px;
	a {
		font-weight: 700;
		&, &:visited, &:focus {
			color: $baseColor;
		}
		&:hover {
			color: $primary;
		}
	}
	.article-comments a {
		font-weight: 400;
	}
	span {
		html[dir="rtl"] & {
			display:inline-block;
		}
		&:before {
			font-size: 14px;
			margin-right:3px;
			.box.blog_articles & {
				margin-right:0;
			}
			line-height: inherit;
			color:$productBtnColor;
			vertical-align: bottom;
		}
		+ span {
			margin-left: 10px;			
			html[dir="rtl"] & {
				margin-left: 0;
				margin-right: 5px;
				&.article-date {
					margin-right: 0;
				}
			}
		}
	}
	+ * {
		margin-top: 28px;
	}
}

.box.blog_articles {
	margin-top:71px;
	.article-sub-title span + .article-date {
		margin-left: 0;
	}
	.article-description {
		@media (min-width: 1480px) {
			padding-right:7%;
		}
		+ .article-sub-title {
			margin-top:55px;
		}
	}
	@media (min-width: 992px) {
		.product-btn-add {
			padding: 18px 29px;
		}
	}
}

.article-description {
	font-size: 16px;
	line-height: 26px;
	ul, ol {
		padding-left: 0;
		list-style-type: none;
		margin: 35px 0 45px;
		li {
			position: relative;
			color: $primary;
			font-size: 16px;
			font-weight: 700;
			text-transform: uppercase;
			padding-left: 50px;
			&:before {
				position: absolute;
				left: 0;
				top: 0;
			}
			+ li {
				margin-top: 16px;
			}
		}
	}
	ul {
		li {
			&:before {
				font-size: 28px;
				
			}
		}
	}
	ol {
		counter-reset: list;
		li {
			counter-increment: list;
			&:before {
				content: counter(list, decimal-leading-zero) '.';
				color: $textPrimary;
				font-size: 16px;
				font-weight: 700;
			}
		}
	}
	+ * {
		margin-top: 19px;
	}
}

.related-article {
	h4 {
		text-transform: uppercase;
		color: $primary;
		a:hover {
			color: $textPrimary;
		}
	}
	.article-sub-title {
		text-align: left;
	}
	.article-sub-title span + span {
		margin-left: 5px;
	}
	.row + .row {
		margin-top: 30px;
	}
	.image {
		margin-top: 5px;
	}
}

.author-info .image {
	float: left;
	margin: 5px 30px 10px 0;
}

.article-comment {
	padding-bottom: 20px;
	margin-bottom: 20px;
	border-bottom: 1px solid $borderColor;
	.author {
		float: left;
		text-transform: uppercase;
		font-size: 16px;
		margin-bottom: 10px;
	}
	.reply-message {
		float: right;
		text-transform: uppercase;
		color: $primary;
		a:hover {
			color: $textPrimary;
		}
	}
	.comment-date {
		clear: both;
		color: $textPrimary;
		text-transform: uppercase;
		&:before {
			font-size: 15px;
			color: $primary;
			
			margin-right: 5px;
		}
	}
	.text {
		margin-top: 15px;
	}
	.article-reply {
		margin: 30px 0 0 60px;
	}
}

.reply-cancel {
	display:inline-block;
	margin:0 10px;
	font-size: 16px;
	color: $primary;
	@include transition(.3s all ease);
	text-transform: uppercase;
	margin-top: 10px;
	&:hover {
		color: $textPrimary;
	}
}
#review-title {
	clear: both;
}

.blog_articles {
	.article-image {
		+ .article-title {
			margin-top: 25px;
		}
	}
}

aside {
	.blog_articles {
		.article-title {
			font-size: 16px;
		}
		.col-sm-4+.col-sm-4{
			margin-top: 30px;
		}
		*+.article-description{
			margin-top: 15px;
		}
	}
	.blog-categories{
		.box-heading{
			margin-bottom: 0;
		}
		ul{
			>li{
				position: relative;
				>i{
					position: absolute;
					top: 10px;
					right: 10px;
					color: $textPrimary;
					@include transition(.3s all ease);
					cursor: pointer;
					font-size: 20px;
					&:hover{
						color: $primary;
					}
				}
				> a {
					background: $stockBg;
					display: block;
					color: $textPrimary;
					font-size: 16px;
					text-transform: uppercase;
					font-weight: 500;
					padding: 10px 10px 10px 20px;
					&:hover{
						background: $stockBgHover;
					}
				}
				+li{
					border-top: 1px solid $borderColor;
				}
			}
			ul{
				display: none;
				padding: 0;
				list-style-type: none;
				border-top: 1px solid $borderColor;
				a{
					padding-left: 40px;
				}
			}
		}
	}
	#blog-search{
		margin: 15px 0 0;
		position: relative;
		input{
			padding-right: 35px;
		}
		a{
			display: inline-block;
			position: absolute;
			right: 10px;
			font-size: 16px;
			line-height: $inputHeight;
			top: 0;
			color: $textPrimary;
			font-family: $fa;
			background: none;
			&:before{
			}
			&:hover{
				color: $primary;
			}
		}
	}
}

.simple_blog-article-view{
	.product_tabs .nav-tabs{
		width: 25%;
	}
	.product_tabs .tab-content{
		width: 75%;
	}
}

/*========================================================
                   Blog Styles Responsive
=========================================================*/
@media (min-width: 1200px) and (max-width: 1479px) {
	.box.blog_articles .article-description + .article-sub-title {
		margin-top: 44px;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.box.blog_articles .article-description + .article-sub-title {
		margin-top: 35px;
	}
	.article-description {
		font-size: 14px;
		line-height: 1.6em;
	}
	.article-sub-title span + span{
		margin-left: 5px;
	}
}

@media (max-width: 991px) {
	.article-image{
		margin-bottom: 15px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.box.blog_articles .article-description + .article-sub-title {
		margin-top: 15px;
	}
	.article-title {
		font-size: 18px;
		line-height: 1.2em;
	}
	.article-description {
		font-size: 13px;
		line-height: 1.5em;
	}
}

@media (max-width: 767px) {
	.simple_blog-article-view{
		.product_tabs{
			.nav-tabs,.tab-content{
				width: 100%;
			}
		}
	}
}

@media (max-width: 600px){
	.reply-cancel{
		float: none;
		display: block;
	}
}

@media (max-width: 479px) {
	.article-sub-title span{
		display: block;
		+span{
			margin: 10px 0 0;
		}
	}
	.article-title {
		font-size: 18px;
		line-height: 1.2em;
	}
	.article-description {
		font-size: 13px;
		line-height: 1.5em;
	}
	.box.blog_articles .article-sub-title span {
		display: inline-block;
		+ span {
			margin-left: 10px;
		}
	}
}