#page {
	background: $contentBg;
	overflow: hidden;
}

.top-line {
	font-family: $baseFontFamily;
	line-height:0;
		border-bottom: 1px solid #ccc;
	
	.container{

		
	}
	[class*="col"] {
		.col-inner > div {
			line-height:16px;
			color: $black;
		}
		+ [class*="col"] {
			margin-top: 1px;
		}
	}	
}
.mid-line{
	.container{
		.tm-inner{
			
			padding-bottom: 12px;
		}
	}
}


.color-primary {
	color:$primary;
}

#content{
	padding-bottom: 30px;
	#common-home & {
		padding-bottom: 10px;
	}
}

@media (min-width: 768px) {
	header {
		position: relative;
		z-index: 2;
	}
	section.top {

		position: relative;
		display: inline-block;
		width: 100%;
	}	
	.mid-line {
		padding-top: 27px;
		padding-bottom: 10px;
		height: 115px;
		position: relative;
	}
}

/*==================  Marked List  ======================*/
.box.account ul, .box.affilate ul {
	padding: 0;
	list-style-type: none;
	li + li {
		border-top: 1px solid $borderColor;
	}
	a {
		position: relative;
		background: $stockBg;
		display: block;
		padding: 13px 15px;
		&:hover{
			background: $stockBgHover;
		}

	}
	a:hover {
		color: $primary;
	}
}

.manufacturer-content div, #content ul.list-unstyled {
	margin: 0;
	list-style-type: none;
	li + li{
		margin-top: 10px;
	}
	a {
		position: relative;
		color: $textPrimary;
		&:hover{
			color: $primary;
		}
		&:before {
			content: '\e11d';
			margin-right: 5px;
			html[dir="rtl"] & {
				content: '\e0c3';
				margin-right: 0;
				margin-left: 5px;
			}
			font-weight: 400;
			font-size: 14px;
			font-family: $material;
			color: $primary;
		}
	}
}

#content .product-info ul.list-unstyled a:before{
	display: none;
}

@media (min-width: 768px) and (max-width: 991px) {
	.mid-line {
		padding-top: 20px;
	}
}

@media (max-width: 767px) {
	.mid-line {
		padding-top: 25px;
	}
	aside {
		margin-top: 20px;
		.container{
			padding: 0;
			width: 100%;
		}
	}
}