/*
* @subsection   RD Parallax
*
* @description  Describes style declarations for RD Parallax extension
*
* @author       Evgeniy Gusarov
* @link         https://ua.linkedin.com/pub/evgeniy-gusarov/8a/a40/54a
* @version      3.0.0
*/
.rd-parallax-inner {
	overflow: hidden;
	position: relative;
	min-height: 100%;
	line-height:$baseLineHeight;
}
.rd-parallax-layer[data-type="html"] {
	position: relative;
	padding-top: 42px;
	padding-bottom: 45px;
}
div.rd-parallax {
	display:inline-block;
	width:100%;
	position:relative;
	background:#f2f2f2;
	overflow:hidden;
	margin-top: 27px;

	[dir="rtl"] & {
		direction: ltr;
	}
	.description {
		padding:62px 50px;
		line-height:$baseLineHeight;
	}
	&-inner{
		z-index:1;
		background:#f2f2f2;
	}
	&-layer{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: 100%;
		-webkit-backface-visibility: hidden;
	}
	&-layer[data-type="media"]{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: 100%;
		-webkit-backface-visibility: hidden;
		iframe{
			width: 100%;
			height: 100%;
		}
	}
	&-layer[data-url]{
		-webkit-background-size: cover;
		background-size: cover;
		background-position: center center;
	}

}
.parallax-desc{
	padding: 199px 0 63px 0;
	text-align: center;
	@media (max-width: 991px) {
		padding: 60px 0;
	}
	@media (max-width: 768px) {
		padding: 70px 0;
	}
	h2{
		font-size: 36px;
		line-height: 36px;
		color: $primary;
		letter-spacing: 2.2px;
		text-transform: uppercase;
		font-weight: 800;	
	
		margin-bottom: 13px;
		@media (max-width: 768px) {
			font-size: 30px;
			line-height: 38px;
		}
	}
	h4{
		font-size: 24px;
		color: $white;
		font-weight: 800;
		text-transform: uppercase;
		letter-spacing: 2px;
	}
	.btn{		
		font-size: 12px;
		color: $white;
		text-transform: uppercase;
		font-weight: 700;
		position: relative;
		padding: 0;
		background: $primary;
		margin-top: 10px;
		border: none;
		padding: 11px 18px;
		border-radius: 0;
		&:hover{
			color: $white;
			&:after{
				width: 0;
				right: auto;
			}
		}
		&:after{
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 1px;
			background: $primary;
			transition: 0.5s;
		}
	}
}
.bg-parallax {
	line-height:0;	
	&, h1, h2, h4, h5, h6, p {
		color: $white;
	}
	h3 {
		color: $primary;
	}
	[dir="rtl"] & {
		text-align: right;
	}
}
//--------------------------------------------------------------
//           Responsive Layout
//--------------------------------------------------------------

.rd-parallax-layer[data-type="html"]{
	
	> div {
		position:relative;
	}
	.box [id*="nav_"] {
		.swiper-button-next,
		.swiper-button-prev {
			color: $primary;
			border: none;
			background: rgba(#000,.2);
			line-height: 30px;
			&:hover {
				color: $black;
			}
		}
	}
}

@media (min-width: 1200px) and (max-width: 1479px) {
	.rd-parallax-layer[data-type="html"] {
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.rd-parallax-layer[data-type="html"] {
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.rd-parallax-layer[data-type="html"] {
	}
}

@media (max-width: 767px) {
	.rd-parallax-layer[data-type="html"] {
	}
}


@media (max-width: 480px) {
	.rd-parallax-layer[data-type="html"] {
	}
}