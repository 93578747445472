//--------------------------------------------------------------
//                    Search Form
//--------------------------------------------------------------
// input variables
$form-bg: #f2f2f2;
$form-height: 50px;
$form-border: 1px solid $borderColor;
$form-color: $baseColor;

.search {
	position: relative;
	input[type="text"],
	button {
		float: left;
		html[dir="rtl"] & { 
			float: right;
		}
		@include border-radius(3px);
		height: $form-height;		
	}
	input[type="text"] {
		width: 100%;
	}
	button {
		position: absolute;
		font-size: 14px;
		color: $white;
		right: 0;
		top: 1px;
		bottom: 1px;
		width: 47px;
		background: $primary;
		border-radius: 0;
		height: auto;

	}
	@media (min-width: 768px) {
		button {
			margin-left: 5px;
			html[dir="rtl"] & {
				margin-left: 0;
				margin-right: 5px;
			}
		}
	}
	@media (min-width: 768px) {
		input[type="text"] {
			width: 230px;
			html[dir="rtl"] & {
				width: 200px;
			}
		}
	}
	@media (min-width: 992px) {
		input[type="text"] {
			width: 420px;
			html[dir="rtl"] & {
				width: 400px;
			}
		}
	}
	@media (min-width: 1200px) {
		input[type="text"] {
			width: 455px;
		}		
	}
	@media (min-width: 1480px) {
		input[type="text"] {
			width: 672px;
		}
	}
	input[type="text"] {
		color: $form-color;
		display: block;
		margin: 0;
		height: 44px;
		font-size: 12px;
		line-height: 22px;
		border-radius: 0;
		padding: (($form-height - 22px) / 2) 35px (($form-height - 22px) / 2) 21px;
		html[dir="rtl"] & {
			padding-left:35px;
			padding-right:41px;
		}
		border: 1px solid #ccc;
		@include box-sizing(border-box);
		background: $white;
		&:focus{
			outline: none;
			box-shadow: none;
		}
		&::-webkit-input-placeholder{
			color: $form-color;
			opacity: 1;
		}
		&:-moz-placeholder{
			color: $form-color;
			opacity: 1;
		}
		&::-moz-placeholder{
			color: $form-color;
			opacity: 1;
		}
		&:-ms-input-placeholder {
			color: $form-color;
			opacity: 1;
		}
	}
	

	button, button:focus, button:active {
		@include transition(.3s all ease);
	}

	button:hover, button:focus:hover, button:active:hover {
		color: $black;	}
}

//--------------------------------------------------------------
//                    Responsive Search Form Layout
//--------------------------------------------------------------



//--------------------------------------------------------------
//                    Live Search
//--------------------------------------------------------------
#livesearch_search_results {
	margin: 3px 0 0;
	padding: 5px;
	position: absolute;
	top: 100%;
	width: 100%;
	left: 0;
	background-color: $stockBg;
	list-style-type: none;
	z-index: 12;
	font-size: 14px;
	max-height: 500px;
	overflow-y: auto;
	overflow-x: hidden;
	@include border-radius(3px);
	li {
		img {
			float: left;			
			margin-right: 10px;
			html[dir="rtl"] & {
				float: right;
				margin-right: 0;
				margin-left: 10px;
			}
			&.loading {
				background: #F5F5F5;
			}
		}
		&:hover,&.highlighted{
			background-color: $primary;
			a,a:visited{
				color: #FFF;
			}
		}

	}
	a,a:visited{
		display: block;
		padding: 5px;
		color: $textPrimary;
		@include clearfix;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	#livesearch_search_results li img{
		display: none!important;
	}
}
