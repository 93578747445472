/*========================================================
                      Banners
=========================================================*/
.banners {
	margin-bottom: 0;
}
.bsnners-bottom{
	margin-top: 55px;
}
.banners{
	.col-xs-12{
		padding-left: 0;
		padding-right: 0;
	}
}
.banner-08{
	.s-desc{
		.banner-title{
			color: $black;
		}
		.banner-price{
			color: $primary;
			letter-spacing: -0.5px;
			@media (max-width: 480px) {
				display: none;
			}
		}
		.banner-desc{
			color: $black;
			letter-spacing: -0.2px;
		}
	}
}
.banner-9{
	.s-desc{
		.banner-price{
			color: $primary;
			margin-top: 21px !important;
		}
	}
}
.banners-top{
	margin-top: 33px;
}
.banners-two-top{
	margin-top: 40px;
}
.banners-bottom{
	margin-bottom: 62px;
}
.banner-4, .banner-5{
	margin-top: 40px;
	@media (max-width: 1199px) {
		margin-top: 28px;
		
	}
}
.banner-1, .banner-2, .banner-3, .banner-4, .banner-5, .banner-6, .banner-7, .banner-8, .banner-9{
	position: relative;
}
.banner-1{
	.s-desc{
		top: auto !important;
		transform: translateY(0) !important;
		bottom: 10%;
		.banner-title{
			color: $primary;

			 + .banner-desc{
			 	margin-top: 0 !important;
			 }
		}
	}
}


.banner-3, .banner-1, .banner-2, .banner-5{
	.banner_img{
		overflow: hidden;
		img{
			transition: .5s;
		}
	}
	&:hover{
		.banner_img{
			img{
				transform: scale(1.2);
			}
			
		}
	}	
}
.banner-3, .banner-4{
	.s-desc{
		.banner-price{
			color: $primary;
		}
	}
}
.banner-2{
	.s-desc{
		.banner-title{
			color: $black
		}
		.banner-desc{
			color: $black;
		}
	}
}
.banner-7{
	@media (max-width: 767px) {
		margin-top: 15px;
	}
}
 .banner-6, .banner-7{
 	.s-desc{
 		.banner-title{
 			color: $black;
 		}
 		.banner-price{
 			color: $primary;
 		}
 		.banner-desc{
 			color: $black;
 		}
 	}
 }
 .banner-9{
 	@media (max-width: 767px) {
 		margin-top: 15px;
 	}
 }

.banner-box {
	position: relative;
	box-shadow: none;
	line-height:0;
	> div > a {
		display:block;
		
	}

	@media (min-width: 768px) {
		float:left;
		width:100%;
		.banners-block-four & {
			width:25%;
		}
		.banners-block-two & {
			width:50%;
		}
	}
	.banner_img {		
		overflow:hidden;
		position:relative;
		width:100%;
		display:inline-block;
		transition: 0.5s;
		@media (max-width: 767px) {
			width: auto;
		}
		
		&:before{
			position: absolute;
			content: '';
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: transparent;
			transition: 0.5s;
		}
		
		.banners-block-four & {
			&:after {
				opacity:.6;
				background: -moz-linear-gradient(top, rgba(#000,0) -1%, rgba(#000,0) 0%, rgba(#000,0.65) 100%);
				background: -webkit-linear-gradient(top, rgba(#000,0) -1%,rgba(#000,0) 0%,rgba(#000,0.65) 100%);
				background: linear-gradient(to bottom, rgba(#000,0) -1%,rgba(#000,0) 0%,rgba(#000,0.65) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
			}
		}
	}	
	.s-desc {
		position: absolute;
		left: 8%;
		
		top: 50%;
		transform: translateY(-50%);
		
		line-height:$baseLineHeight;		
		text-align: left;
		color: #fff;
		@media (max-width: 767px) {
			left: 50%;
			transform: translate(-50%, -50%) !important;
		}
		.banner-title{			
			font-size: 24px;
			line-height: 31px;
			font-weight: 800;
			text-transform: uppercase;	
			letter-spacing: 2px;	
			@media (max-width: 991px) {
				font-size: 15px;
				line-height: 18px;
			}
			+ .banner-price{
				margin-top: 10px;
			}
			+ .banner-desc{
				margin-top: 17px;
			}
		}
		.banner-price{
			font-size: 24px;
			line-height: 24px;
			font-weight: 800;
			 + .banner-desc{
			 	margin-top: 8px;
			 }

		}
		.banner-desc{
			font-size: 14px;
			line-height: 24px;
			font-weight: 600;
			@media (max-width: 1199px) {
				display: none;
			}
		}
		
		.btn-link{
		
			font-size: 12px;
			color: $white;
			background: $primary;
			padding: 10px 20px;
			text-transform: uppercase;
			margin-top: 23px;
			font-weight: 700;
			letter-spacing: 0.7px;
			&:hover{
				text-decoration: none;
				background: $black;
			}
			
		}

		
	}

	img {
		
		width: 100%;
	}	
	h2, h3, h4, h5, h6, p, span {
		margin: 0;
		color:$white;
		.color-primary {
			color:$primary;
			letter-spacing:1px;
		}
	}
	h2 {		
		font-weight:400;
		font-size:60px;
		line-height:1em;
	}
	h3 {
		font-size:60px;
		font-weight:700;
		margin-bottom:0;
		@media  (min-width: 768px) {
			@include transition(.3s color ease);
		}		
	}
	.btn-primary {
		display:inline-block;
		margin-top:20px;
		@media  (min-width: 768px) {
			@include transition(.3s background-color ease);
		}
	}
	p {
		font-size:22px;
		margin-bottom:5px;
	}
	span.btn-link {
		display:inline-block;
		margin-top:30px;
		font-size:14px;
		line-height:1.2em;
		font-weight:700;
		text-decoration:none !important;
		position:relative;
		@include transition(.5s all ease);
		&:before {
			position:absolute;
			top:100%;
			left:0;
			width:100%;
			height:1px;
			content:'';
			display:inline-block;
			background: $white;
			@include transition(.5s all ease);
		}
		&, &:visited, &:focus {
			color: $white;
		}
		@media (min-width: 768px) {
			&:hover {
				color: $primary;				
				&:before {
					background-color: $primary;
					left:50%;
					width:0;
				}
			}
		}
	}		
	@media (min-width: 768px) {
		&:hover {
			h3 {
				color:$primary;
			}
			
			.banners-block-four & {
				.banner_img:after {
					opacity:0;
				}
			}
		}
	}
}














/*========================================================
                   Responsive Banners
=========================================================*/

@media (min-width: 1200px) and (max-width: 1479px) {
	.banner-box {
		h2 {
			font-size: 48px;
		}
		h3 {
			font-size: 48px;
		}
		.banner-big {
			h2 {
				font-size: 64px;
			}
			.btn-primary {
				margin-top: 20px;
			}
		}
	}
	.banners-block-four .banner-box h3 {
		font-size: 22px;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.banner-box {
		h2 {
			font-size: 38px;
		}
		h3 {
			font-size: 38px;
		}
		.banner-big {
			h2 {
				font-size: 50px;
			}
			.btn-primary {
				margin-top: 16px;
			}
		}
	}
	.banners-block-four .banner-box {
		h3 {
			font-size: 18px;
		}
		.s-desc {
			bottom: 28px;
		}
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.banner-box {
		h2 {
			font-size: 30px;
		}
		h3 {
			font-size: 30px;
		}
		.banner-big {
			h2 {
				font-size: 40px;
			}
			.btn-primary {
				margin-top: 12px;
			}
		}
	}
	.banners-block-two .banner-box h3 + p {
		margin-top: 15px;
	}
	.banners-block-four .banner-box {
		h3 {
			font-size: 16px;
		}
		.s-desc {
			bottom: 22px;
		}
	}
}

@media  (max-width: 767px) {	
	.banner-1, .banner-2, .banner-3, .banner-5{
		margin-bottom: 15px;
	}
	.banners {
		text-align:center;
		line-height:0;
		.banner-box .s-desc {
			line-height:$baseLineHeight;
		}
	}
	.banner-box {
		width:767px;
		max-width:100%;
		float:none;
		display:inline-block;
		.banner-big {
			h2 {
				font-size: 32px;
			}
			h3 {
				font-size: 24px;
			}
			.btn-primary {
				margin-top: 7px;
				padding: 7px 15px;
			}
		}
		span.btn-link {
			margin-top: 20px;
		}
	}
	.banners-block-two .banner-box {
		h2 {
			font-size: 30px;
		}
		h3 {
			font-size: 24px;
			+ p {
				margin-top: 15px;
			}
		}
	}
}
