.zemez-single-product {
	position:relative;
	display: inline-block;
	width:100%;
	margin: 54px 0 0;
	h2{
		font-size: 22px;
		a{
			color: $black !important;
			&:hover{
				color: $primary !important;
			}

		}
	}
	.description{
		display: block !important;
		font-size: 16px;
		color: $priceOld;
		line-height: 26px;
	}
	.btn{
		background: $primary !important;
		color: $white !important;
		margin-bottom: 20px;
		border: none !important;
		padding: 12px 13px 13px !important;
		@media (max-width: 991px) {
			margin-bottom: 0;
			margin-left: -40px;
		}
		@media (max-width: 767px) {
			margin-left: 0;
		}
		&:hover{
			background: $bgGray !important;
		}
	}

	.product-btn{
		background: $bgGray !important;
		color: $productBtnColor !important;
		&:hover{
			background: $primary !important;
			color: $white !important;
		}
	}
	.sbHolder, .radio{
		width: 200px;
	}
	.main-image {
		position: relative;
		float: right;
		width: 70%;
		background: #f4f4f4;
	}
	.options {
		margin-top: 15px;
	}
	.thumbnails {
		position: relative;
		float: left;
		width: 27%;
		margin: 0;
	
		li {
			background: #f4f4f4;
			> a {
				
				&:hover {
					box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.15);
				}
			}
		}
	}
	.button-wrap {
		margin-top: 30px;
		text-align: center;
		@media (min-width: 992px) {
			text-align: left;
		}
		[class*="col-"] {
			+ [class*="col-"] {
				margin-top: 15px !important;
				@media (min-width: 992px) {
					margin-top: 0 !important;
				}
				@media (max-width: 767px) {
					text-align: left;
				}
				+ [class*="col-"] {
					margin-top: 15px !important;
				}
			}
		}
		.quantity {
			margin-top: 6px;			
			position: relative;
			overflow: hidden;
			width: 61px;
			height: 50px;
			float: left;
			margin-right: 10px;
			input{
				width: 61px;
				height: 50px;
				color: #949494;
				border-color: #e1e1e1;
				background: #fff;
				padding: 12px 20px 12px 5px;
			}
			.counter {
				margin-top: 1px;
				position: absolute;
				display: inline-block;
				top: 11px;
				right: 8px;
				width: 12px;
				height: 10px;
				line-height: 10px;
				padding: 0;
				font-size: 14px;
				background: transparent;

			}
			.counter-minus{
				top: auto;
				bottom: 11px;
				padding: 0;
			}

		}
		.btn {
			display: block;
			@media (max-width: 991px) {
				display: inline-block;
			}
		}
	}
	.fa-plus:before {
		content: "\f0d8";
	}
	.fa-minus:before {
		content: "\f0d7";
	}
	.form-group {
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 16px;
		html[dir="rtl"] & {
			text-align: right;
		}
	}
	.nav-tabs > li + li {
		margin-left: 18px;
	}
	.product-thumb {
		background: none;
		text-align: left;
		.product-options {
			border: 0;
			display: block;
			.group {
				margin-bottom:10px;
			}
		}
		.description {
			margin: 20px 0;
			@media (max-width: 991px) {
				display: none !important;
			}
		}
		.price {
			width: auto;
			margin-top: 17px;
			margin-left: -55px;
			color: $primary;
			font-size: 20px;
			@media (max-width: 767px) {
				margin-top: 7px;
				margin-left: 0;
			}
			@media (max-width: 600px) {
				margin-left: 0;
			}
			@media (max-width: 400px) {
				margin-left: 20px;
			}
		}
	}
	.attributes {
		.title, .descr {
			display:inline-block;
		}
	}
	.bx-prev, .bx-next {
		position:absolute;
		display:inline-block;
		padding: 5px;
		text-align:center;
		color: $white;
		background: rgba($primary, .8);
		left:0;
		right:0;
		&:hover {
			background: lighten($primary, 20%);
		}
	} 
	.bx-prev {
		top:0;
	}
	.bx-next {
		bottom:0;
	}
	.counter, .counter:focus {
		display: inline-block;
	}
}
@media (max-width: 1199px) {
	.zemez-single-product .sbHolder {
		width: 100%;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.zemez-single-product {
		h2 {
			font-size: 20px;
		}
		div.form-group {
			margin-bottom: 20px;
		}
	}
}

@media (max-width: 991px) { 
	.zemez-single-product {
		.product-thumb {
			margin-top: 30px;
			h2 {
				margin: 0 0 20px;
			}
			.button-wrap {
				display: inline-block;
				.btn {
					margin-right: 10px;
				}
			}
		}
		.price-old,
		.price-new {
			line-height: 25px;
		}
		.price {
			display: inline-block;
		}
		div.form-group {
			margin-bottom: 15px;
		}
		.nav-tabs > li + li {
			margin-left: 7px;
		}
		.product_tabs {
			font-size:12px;
			line-height:1.2em;
		}
		.sbHolder {
			height: 40px;
		}
		.sbSelector {
			padding-top: 7px;
			padding-bottom: 7px;
		}
		.sbToggle {
			line-height: 38px;
		}
	}
}

@media (max-width: 767px) {
	.zemez-single-product {
		.thumbnails {
			width: 26.26%;
		}
	}
}