$dropdown-bg: rgba(#000,.6);

header {
	.box-cart {
		display:inline-block;
		vertical-align:top;
		float: right;
		margin-left: 28px;
		margin-top: 19px;
		strong{
			font-weight: 400;
			color: $primary !important;
		}
		span{
			color: $primary;
			font-weight: 400;
		}
		html[dir="rtl"] & {
			text-align: right;
			margin-left: 0;
			margin-right: 28px;
		}
		&:hover{
			span{
				color: $primary !important;
			}
		}
	}
	.search {
		display:inline-block;
		vertical-align:top;
		text-align: left;
		
		margin-top: 2px;
		@media (max-width: 767px) {
			display: block;
			margin-top: 10px
		}
		html[dir="rtl"] & {
			text-align: right;
			margin-left: 0;
			margin-right: 38px;
		}
	}
	.logo {
		font-size: 18px;
		line-height: 22px;
		a {
			display:inline-block;
			line-height:1.2em;
		}
		@media (min-width: 768px) {
			float: left;
			html[dir="rtl"] & {
				float: right;
			}
		}
		margin: 0;
		&:after {
			display: none;
		}
	}	
	@media (min-width: 768px) {
		.box-comment,
		.box-nav,
		.box-currency,
		.box-language {
			float: left;
			html[dir="rtl"] & {
				float: right;
			}
			margin-bottom: 9px;
		}
	}
	.logo {
		margin-top: 20px;
		margin-bottom: 10px;
	}
	.box-open,
	.box-phones {
		display: block;
		
	}	
	.box-open{
		font-weight: 600;
		letter-spacing: 0.5px;

	}
	.box-phones {
		padding-top: 15px;
		border-right: 1px solid #c7c7c7;
		padding-bottom: 19px;
		padding-right: 27px;
		letter-spacing: 1.5px;
		@media (max-width: 1440px) {
			border-right: none;
			padding-right: 2px;
		}
		span{
			font-size: 10px;
			text-transform: uppercase;
			font-weight: 600;

		}
		a {
			font-weight: 600;
			font-size: 10px;
			letter-spacing: 0.1px;
			&, &:visited, &:focus {
				color: $black;
			}	
			&:hover {
				color: $primary;
			}
		}
	}
	@media (min-width: 768px) and (max-width: 1199px) {
		.box-comment {
			width: 100%;
			text-align: center;
			margin-bottom: 10px;
		}
	}	
	@media (min-width: 768px) {
		* {
			+ .box-comment,
			+ .box-nav,
			+ .box-currency,
			+ .box-language {
				margin-left: 24px;
				html[dir="rtl"] & {
					margin-left: 0;
					margin-right: 24px;
				}
				position:relative;
				&:before {
					position:absolute;
					top:-15px;
					left:-12px;
					html[dir="rtl"] & {
						left:auto;
						right:-12px;
					}
					width:1px;
					height:51px;
					display:inline-block;
					content:'';
					background:#ccc;
					@media (max-width: 1199px) {
						display: none;
					}
				}
			}
		}
	}
	@media (min-width: 1200px) {
		.box-open,
		.box-phones {
			display: inline-block;
			
		}
		* {
			+ .box-open {
				margin-left: -20px;
				html[dir="rtl"] & {
					margin-left: 0;
					margin-right: 7px;
				}
			}
		}
	}
	@media (min-width: 1480px) {
		*+ .box-open {
			margin-left: 10px;
			html[dir="rtl"] & {
				margin-left: 0;
				margin-right: 7px;
			}
		}
	}
}
.navigation{
	background: $primary;
}
.top-line {
	color:$white;
	a {
		font-size: 10px;
		
		&, &:visited, &:focus {
			color: $black;
		}
		@media (min-width: 768px) {
			&:hover {
				color: $primary;
			}
		}
	}
	.pull-right-md{
		padding-top: 13px;
		.box-comment{
			font-weight: 600;
			font-size: 10px;
			
		}
	}
}

.mid-line {
	
	margin-bottom:30px;
	.common-home & {
		background:none;
		margin-bottom:0;
	}
	> .homebuilder > .tm-container > .tm-inner {
		position:relative;
	}
	@media (min-width: 768px) and (max-width: 1199px) {
		.col-lg-10 {
			position:static;
		}
	}
	color:$white;
	a {
		&, &:visited, &:focus {
			color: $white;
		}
		&:hover {
			color: $primary;
		}
	}
}
.box-phones,
.box-open{
		text-transform: uppercase;
}
.box-phones,
.box-open,
.box-comment,
.box-nav,
.nav {
	display: inline-block;
	position:relative;
	font-size: 10px;
	line-height: 16px;

	font-weight: 600;
	[class*="linearicons-"] {
		&:before {
			margin-right: 5px;
			font-size: 10px;
			line-height: 14px;
			html[dir="rtl"] & { 
				margin-right: 0;
				margin-left: 5px;
			}
		}
	}
}

.nav span.toggle {
	cursor:pointer;
	color:$black;
	font-size: 10px;
	
	@media (min-width: 768px) {		
		@include transition(0.3s all ease);
	}
	span:after {
		display: inline-block;
		content: '\f107';
		margin-left: 5px;
		html[dir="rtl"] & {
			margin-left: 0;
			margin-right: 5px;
		}
		font-weight: 400;
		font-family: "FontAwesome";
	}
	&:hover {
		color:$primary;
	}
}

.box-phones,
.box-open,
.box-comment {
	font-weight: 700;
	[class*="linearicons-"], span {
		font-weight: 600;
	}
}

.box-nav {
	> * {
		+ * {
			margin-left: 22px;
			position:relative;
			html[dir="rtl"] & {
				margin-left: 0;
				margin-right: 22px;
			}
			&:before {
				position:absolute;
				top:-15px;
				left:-13px;
				width:1px;
				height:51px;
				display:inline-block;
				content:'';
				background:#ccc;
				@media (max-width: 1199px) {
					display: none;
				}
				html[dir="rtl"] & {
					left:auto;
					right:-13px;
				}
			}
		}
	}
}

.logo {
	margin: 0;
	&:after {
		display: none;
	}
}

/*================  Toggle ===============*/
.toggle-wrap {
	position: relative;
}
.toggle {
	&_cont {
		display: none;		
		background: $dropdown-bg;
		padding: 0;
		margin: 0;
		list-style-type: none;
		position: absolute;
		right: 0;
		@media (min-width: 768px) {
			html[dir="rtl"] & {
				right: auto;
				left: 0;
			}
		}
		top: 100%;
		top: calc(100% + 18px);
		width: 200px;
		z-index: 20;
		> li {
			> a, > button {
				text-align: left;
				html[dir="rtl"] & {
					text-align: right;
				}
				width: 100%;
				display: block;
				padding: 10px 10px 10px 15px;
				text-transform: capitalize;
				&:hover {
					background: $primary;
					color: #FFF;
				}
			}
			> button {
				color: inherit;
			}
		}
		ul {
			text-align: right;
		}
		&__right{
			right: auto;
			left: 0;
		}
	}
}

/*================   Top Links style   ===============*/
$top-links-item-margin: 20px;

header .nav {
	@media (min-width: 768px) {
		> .toggle {
			display: none;
		}
		> .toggle_cont {
			display: block !important;
			position: static;
			padding: 0;
			margin: 0;
			background: none;
			width: auto;			
			> li {
				display: inline-block;
				+ li {
					margin-left: $top-links-item-margin;
				}
				> a {
					display: inline-block;
					padding: 0;
					&:hover, &.current {
						color: $textPrimary;
						background: none;
					}
				}
			}
			.common-home & > li.first > a {
				color: $primary;
			}
		}
	}
	@media (max-width: 767px) {
		position: fixed;
		top: 0;
		margin: 0;
		z-index: 10000;
		.toggle-wrap {
			.toggle {
				display: none;
			}
			.toggle_cont {
				display: block !important;
				height: auto !important;
				position: static;
				padding: 0;
				@include transition(0);				
				li + li {
					margin-top: 0;
				}
			}
		}
	}
}

/*================  CURRENCY AND LANGUAGE BOXES ===============*/
.box-language,
.box-currency {
	font-size: 10px;
	line-height: 16px;
	font-weight: 600;
	
	.toggle-wrap {
		vertical-align:top;
	}
	.toggle {
		display: block;
		text-align: center;
		text-transform: capitalize;
		cursor: pointer;
		@media (min-width: 768px) {
			@include transition(.3s all ease);
		}
		&:after {
			display: inline-block;
			content: '\f107';
			margin-left: 2px;
			font-weight: 400;
			font-family: $fa;
			html[dir="rtl"] & {
				margin-left: 0;
				margin-right: 2px;
			}
		}
		color: $black;
		&.active, &:hover {
			color: $primary;
		}
		&_cont {
			> li {
				> a, > button {
					&:hover, &.selected {
						background: $primary;
						color: $white;
					}
				}
			}
		}
	}
}

.box-currency {
	.toggle {
		&_cont {
			right: auto;
			left: 0;			
			@media (min-width: 768px) {
				right: 0;
				left: auto;
				html[dir="rtl"] & {
					right: auto;
					left: 0;
				}
			}
		}
	}
}

.box-language,
.box-currency {
	.toggle {
		&_cont {
			top: calc(100% + 18px);
		}
	}
}

/*========================================================
               Responsive Header styles
=========================================================*/

@media (min-width: 992px) and (max-width: 1199px) {
	header {
		.box-cart {
			margin-left: 15px;
		}
		
		h1.logo {
			font-size:20px;
		}
		.box-nav {
			margin-left:0;
			&:before {
				display:none;
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	header {
		font-size:11.5px;
		.box-cart {
			margin-left: 10px;
			float: right;
			[dir="rtl"] & {
				margin-left: 0;
				margin-right: 10px;
				float: left;
			}
		}
		div.search {
			margin-left: 18px;
			float:right;
			[dir="rtl"] & {
				margin-left: 0;
				margin-right: 15px;
				float:left;
			}
		}
		.logo {
			margin-bottom: 5px;
		}
		h1.logo {
			font-size:18px;
		}
		.box-nav > * + *,		
		* + .box-comment, 
		* + .box-nav, 
		* + .box-currency, 
		* + .box-language{
			margin-left:8px;
			html[dir="rtl"] & {
				margin-left:0;
				margin-right:8px;
			}
			&:before {
				display:none;
			}
		}
	}
	.box-phones, .box-open, .box-comment, .box-nav, .nav {
		font-size: 11px;
	}
	.box-language .toggle:after, .box-currency .toggle:after {
		margin-left: 0;
	}
	.nav span.toggle span:after {
		margin-left: 3px;
	}
}

@media (max-width: 767px) {
	.toggle_cont {
		background: $black;
	}
	.common-home .mid-line {
		background: #f5f5f5;
	}
	header {	
		nav .toggle_cont {
			top:100%;
		}
		.search {
			float: none;
			margin-left: 0;
			margin-bottom: 0;
			html[dir="rtl"] & {
				margin-right: 0;
			}
		}
		.box-currency {
			margin-right: 0;
			html[dir="rtl"] & {
				margin-left: 0;
			}
			padding: 0;
		}
		.bg-dark {
			padding-bottom:10px;
		}
		.logo {
			float: none;
			text-align: center;
			margin-bottom: 0;
			img {
				display: inline-block;
			}
		}
		.box-cart {
			float: none;
			margin-top: 0;
		}
	}
	header {
		.box-comment, 
		.box-nav, 
		.box-currency,
		.box-language {
			float:none;
			display:inline-block;
		}
		.box-comment {
			width:100%;
			margin-bottom:10px;
		}
		.pull-right-md {
			text-align:center;
			
		}
		.box-currency, .box-language {
			.toggle_cont {
				right: auto;
				left: 50%;
				width:80px;
				margin-left:-40px;
			}
		}
		.box-currency .toggle_cont {
			width:100px;
			margin-left:-50px;
		}
	}
	.box-nav > * + *,
	header * + .box-comment, 
	header * + .box-nav, 
	header * + .box-currency, 
	header * + .box-language{
		margin-left: 8px;
	}	
}