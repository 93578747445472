/*========================================================
                        Buttons
=========================================================*/
#button-search {
	+ h2 {
		margin-top: 15px;
	}
}
.button-group{
	text-align: center;
}
.button-wrap{
	float: right;
	margin-top: 7px;
	@media (max-width: 1199px) {
		margin-top: 0;
	}
}
.buttons {
	margin: 1em 0;
	@include clearfix;
	.btn {
		padding: 14px 20px;
		margin-bottom: 10px;
		&:hover {
			@include box-shadow(none);
		}
		i, span {
			vertical-align: middle;
		}
		i {
			font-size: 28px;
			margin: 0 5px;
			display: inline-block;
			line-height: $baseLineHeight;
		}
	}
	.pull-right {
		.btn {
			text-transform: uppercase;
			margin-right: 10px;
			&:hover{
			}
		}
		[for="agree1"] + .btn {
			margin-bottom: 0;
		}
	}
}

.input-group-btn .btn {
	padding: 7px 12px;
	i {
		font-size: 15px;
		line-height: 18px;
	}
}

.agree{
	b{
		@include transition(.3s all ease);
	}
	&:hover b{
		color: $primary;
	}
}

.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open>.dropdown-toggle.btn-primary.focus, .open>.dropdown-toggle.btn-primary:focus, .open>.dropdown-toggle.btn-primary:hover {
	color: $primary;
    background-color: #f2f2f2;
    border-color: $primary;
}

/* ================   Default Buttons BEGIN   =============== */
 .btn, .btn-default, .btn-primary, .btn-success, .btn-info, .btn-warning, .btn-danger {
	font-size:14px;
	font-weight:700;
	line-height:21px;
	border: 1px solid $black;
	text-shadow: none;
	padding: 12px 30px 13px;
	outline: none;
	@include border-radius(3px);
	i {
		font-size:21px;
		line-height:21px;
		margin-right:2px;
	}
	span {
		vertical-align:top;
		padding-top: 2px;
		display: inline-block;
	}
	&, &:visited {
		color: $black;
		background: $white;
		border-color: $black;
	}
	&:hover,
	&:focus,
	&:active	{
		background: #333;
		border-color: #333;
		color: $white;
		outline: none !important;
	}
}
.btn-primary {
	border:none !important;
	padding: 14px 28px 15px;
	&, &:visited {
		color: $white;
		background: $primary;
	}
	&:hover,
	&:focus,
	&:active	{
		color: $white;
		background: #333;
		outline: none !important;
	}
}


/*================   Product Buttons BEGIN   ===============*/
.product-btn {
	color:#333333;
	


	text-align:center;

	font-size: 14px;
	&:hover {
		color: $primary;
	}
}


/* ================   QuickView  =============== */
.product-thumb .image > .quickview {
	position: absolute;
	left: 50%;
	top: 50%;
	display: inline-block;
	opacity: 0;
	font-size: 30px;
	z-index: 1;
	transform: translate(-50%, -50%);
	@include transition(all .5s ease);
}


/* ================   Product page buttons  =============== */
.product-buttons {
	@include clearfix;
	margin: 10px 0;
	padding: 0;
	li {
		display: inline-block;
		margin-right: 10px;
		[dir="rtl"] & {
			margin-right: 0;
			margin-left: 10px;
		}
		list-style: none;
		button {
			display: inline-block;
			background: none;
			color: $textPrimary;
			padding: 0;
			margin-bottom:5px;
			&:hover,
			&:focus {
				color: $primary;
				background: transparent;
				i {
					color: $primary;
				}
			}
			&:active {
				box-shadow: none;
			}
			i {
				margin-right:3px;
				color: $productBtnColor;
			}
		}
	}
}

#button-cart {
	display: inline-block;
	vertical-align: middle;
	padding-top:13px;
	padding-bottom:13px;
	margin-top:5px;
	margin-bottom:5px;
	&:disabled {
		pointer-events: none;
		opacity: .5;
	}
}

/* ================   Tooltip  ================= */
.tooltip-inner {
	background: $primary;
}
[class][class].tooltip-arrow {
	border-top-color: $primary;
}
/*========================================================
                 Responsive styles Buttons
=========================================================*/
@media (min-width: 992px) and (max-width: 1199px) {
	.account-wishlist table td .btn {
		padding: 10px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.table-bordered > tbody > tr > .text-right {
		padding: 5px;
	}
	.btn, .btn-default, .btn-primary, .btn-success, .btn-info, .btn-warning, .btn-danger {
		padding-left: 18px;
		padding-right: 18px;
	}
	.btn-primary {
		padding-left: 18px;
		padding-right: 18px;
	}
}

@media (max-width: 767px) {
	#grid-view, #list-view {
		display: none;
	}
	.product-thumb .image  .quickview {
		display: none;
	}
}

.mobile, .tablet {
	.product-thumb .image { 
		.quickview {
			display: none;
		}
		.button-wrap {
			opacity: 1;
			@include transform(translateX(-50%) translateY(-50%));
			@include transition(none);
			.product-btn, .product-btn + .product-btn, .product-btn + .product-btn + .product-btn {
				@include transition(none);
				@include transform(none);
			}
		}
	}
}


@media (max-width: 479px) {
	.buttons {
		.pull-left, .pull-right {
			float: none !important;
			margin-left: 0;
		}
	}
	#button-cart{	
		margin: 15px 0 0;

	}
}

@media (max-width: 410px) {
	.account-address {
		.table td a {
			display: block;
			+ a {
				margin-top: 5px;
			}
		}
	}
}


