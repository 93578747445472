footer {
	
	color:#333333;
		background: transparent;
	font-size: 12px;
	line-height: 21px;
	h1, h2, h3, h4, h5, h6 {
		color:#333333;
		
		text-align: left;
		@media (max-width: 767px) {
			text-align: center;
		}
	}
	h1, h2, h3, h4, h5, h6, p {
		margin: 0;
	}
	a {
		&, &:visited, &:focus {
			color: #333333;
		}
		&:hover {
			color: $primary;
		}
	}
	.bg-primary {
		padding: 20px 0 6px 0;
	}
	@media (max-width: 767px) {
		.module-social {
			margin-bottom: 15px;
		}
	}
	@media (min-width: 768px) {
		.module-social,
		.box-payments {
			float: left;
			@media (max-width: 991px) {
				float: none;
				margin-top: 10px;
			}
			html[dir="rtl"] & {
				float: right;
			}
		}
	}
	@media (min-width: 992px) and (max-width: 1199px) {
		.module-social {
			+ .box-payments {
				margin-left: 50px;
			}
		}
	}
	@media (min-width: 768px) {
		.module-social {
			+ .box-payments {
				float: right;
				margin-right: 7%;
				margin-left: 10px;
			}
		}
	}
	
	
	.box .box-heading {
		background:none;
		padding:0;
		margin-bottom:30px;
	}
	address,
	.footer_box {
		[class*="linearicons"] {
			&:before {
				font-size: 14px;
				line-height: 14px;
				margin-right: 10px;
				color:#333333;
			}
		}
	}
	address {
		margin-bottom: -5px;
		padding-top: 3px;
		+ p {
			margin-top:14px;
		}
		dl {
			@include clearfix();
			margin-bottom: 11px;
			dt, dd {
				@media (min-width: 768px) {
					float: left;
					html[dir="rtl"] & {
						float: right;
					}
				}
			}
			dt {
				margin-right: 2px;
				html[dir="rtl"] & {
					margin-right: 0;
					margin-left: 2px;
				}
				font-weight: 500;
			}
			dd {
				font-weight: 500;
			}
		}
	}
	.footer_box {
		p {
			span {
				font-weight: 500;
			}
			font-weight: 500;
		}
	}
	h5 {
		font-size: 20px;
		+ ul {
			margin-top: 15px;
		}
	}
	.box .box-heading h3, h3 {
		font-size: 18px;
		line-height: 18px;
		font-weight: 500;
	}
	ul li + li {
		margin-top: 7px;
	}
	h3 {
		+ p, + ul, + .box-content {
			margin-top: 24px;
			font-size: 12px;
			font-weight: 500;
		}
	}
	p {
		+ p {
			margin-top: 11px;
		}
	}
	@media (max-width: 767px) {
		[class*="col-"] {
			margin-bottom: 35px;
		}
		[class*="col-"]:last-of-type, .bg-dark [class*="col-"] {
			margin-bottom: 0;
		}
	}
}

#common-home + footer {
	margin-top: 34px;
}

.bg-footer {
	padding-top:28px;
	padding-bottom: 19px;
	background: transparent;
	background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
	position:relative;
	> div {
		position:relative;
	}
	
}

.copyright br {
	display: none;
}

.copyright {
	font-size: 14px;
	color: #333333;
	@media (max-width: 1440px) {
		font-size: 12px;
	}
	@media (min-width: 768px) {
		float: right;
		html[dir="rtl"] & {
			float: left;
		}
	}
	a {
		&, &:visited, &:focus {
			color: #333333;
		}	
		&:hover {
			color: $primary;
		}
	}
	span {
		color:$white !important;
	}
	margin-top: 5px;
	>.container {
		padding-top: 30px;
		padding-bottom: 30px;
	}
}
.bg-copy{
	padding: 30px 0 0 0;
	border-top: 1px solid #cccccc;
	margin-top: 4px;
	.footer-logo{
		float: left;
		margin-right: 161px;
		@media (max-width: 1440px) {
			margin-right: 30px;
		}
		@media (max-width: 991px) {
			float: none;
			margin-right: 0;
			margin-bottom: 10px;
		}

	}
}
/*========================================================
                    Responsive Footer
=========================================================*/

@media (max-width: 991px) {
	.copyright {
		margin-top: 5px;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	footer .module-social + .box-payments {
		margin-right: -1%;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	footer {
		.module-social + .box-payments {
			margin-right: 5px;			
		}
	}	
}

@media (min-width: 479px) and (max-width: 767px){
	footer {
		[class*="col-xs"]:nth-child(2n-1){
			clear: both;
		}
	}

}

@media (max-width: 767px) {
	footer {
		text-align: center;
		address dl dd {
			line-height:2em;
		}
		.box .box-heading {
			margin-bottom: 20px;
		}
		h3 { 
			+ p, + ul, + .box-content {
				margin-top: 20px;
			}
		}
		.bg-primary [class*="col-"] {
			margin-bottom: 15px;
		}
	}
	.bg-footer {
		padding-top: 45px;
		padding-bottom: 30px;
		&:before {
			background-color: rgba(#000, 0.7);
		}
	}	
}

@media (max-width: 479px) {
	footer [class*="col-xs"]{
		float: none;
		width: 100%;
	}
}