//--------------------------------------------------------------
//                    Variables
//--------------------------------------------------------------


// Colors
$baseColor    : #777;
$primary      : #0073d6;
$textPrimary  : #333;
$borderColor  : #e0e0e0;
$stockBg      : #f5f5f5;
$stockBgHover : darken(saturate(adjust_hue($stockBg, 0), 0%), 3.1372549019607874%);
$primaryHover : darken(saturate(adjust_hue($primary, -0.07142857142855519), 0%), 8.235294117647058%);	

//Backgrounds
$headerBg  : #fff;
$contentBg : #fff;
$footerBg  : #f6d228;
$baseBg    : $footerBg;

// Fonts
$baseFontFamily : 'Montserrat', sans-serif;
$baseFontSize   : 14px;
$baseLineHeight : 24px;
$baseFont       : 400 #{$baseFontSize}/#{$baseLineHeight} $baseFontFamily;



//Olark Chat Background
$olark-heading-bg: $primary;

// Border-radius
$baseBorderRadius: 3px;

//Box Shadow
$baseBoxShadow: 1px 1px 8px rgba(#000,.15);

$white : #fff;
$black : #000;

$bgGray: #f2f2f2;
$bgDark: #000;
$footerbg: #f7f7f7;
// components
$cartBg      : $white;
$cartItemsBg : #f2f2f2;

// Search
$searchWidth        : 100%;
$searchHeight       : 53px;
$searchBorder       : 1px solid #d9d9d9;
$searchColor        : $primary;
$searchPaddingRight : 30px;
$searchPaddingLeft  : 30px;

$productBtnBg     : #f2f2f2;
$productBtnBorder : #dedede;
$productBtnColor  : #b4b4b4;

$prpductBtnAddHover: #303030;

$rating     : #b4b4b4;
$ratingStar : #ffb503;

$price    : #333;
$priceNew : $primary;
$priceOld : #777;

$sale: $primary;

$socialBg: #2c2c2c;

$newsletterBorderColor: darken( saturate( adjust-hue( $primary, 3deg ), 2 ), 12 );

$htmlIcon: #b4b4b4;
$swiperButton: #e0e0e0;
$navBg: #f2f2f2;
$navBorder: #dedede;

$cartBtnL: #f2f2f2;
$cartBtnR: $primary;

$sliderArrowColor: darken( desaturate( adjust-hue( $primary, -2deg ), 11 ), 4 );