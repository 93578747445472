/*==================  Stuck menu  ======================*/

$stuck-bg: $stockBg;

.stuck-menu{
	width: auto !important;
}
.ajax-overlay-open .scroll-fix{
	z-index: 996;
}

@media only screen and (min-width: 991px) {
	.scroll-fix {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 10000;
		@include transition(.3s all ease);

    // Custom styles
    background: $stuck-bg;
    padding: 10px 0;
 }
}

@media only screen and (max-width: 991px) {
	.stuck-menu {
		position: static;
		+.stuck-menu{
			display: none;
		}
	}
}