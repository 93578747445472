//--------------------------------------------------------------
//                    NewsLetter Block
//--------------------------------------------------------------
.newsletter {
	.box-heading{
		h3{
			text-transform: none;

		}
	}
	.box-content {
		position:relative;
		form {
			position:relative;
		}
	}
	&-description {
		margin-bottom:13px;
	}
	#zemez-newsletter-form {
		padding-top:12px;
	}
	.zemez-login-form {
		position:relative;
		display:inline-block;
		max-width:100%;
		margin-right:2px;
		width: 100%;
		label {
			display: none;
		}
		
	}
	input {
		width: 100%;
		max-width:100%;
		margin-bottom:5px;
		border: 3px solid #f1f1f1;
	}	
	@media (min-width: 1480px) {
		html[lang="en"] & {
			.btn-primary {
				width: 83px;
			}
		}
		html[lang="ru"] & {
			input {
				width: 160px;
			}
		}
		html[lang="de"] & {
			input {
				width: 215px;
			}
		}
		.zemez-login-form {
			display: inline-block;
		}
	}
	input {
		display:inline-block;

		@include border-radius(3px);
		height: 50px;
		padding: 8px 21px 8px 26px;
		html[dir="rtl"] & {
			padding-left:21px;
			padding-right:48px;
		}
		background: $white;
		color: $black;
		font-size: 14px;
		line-height: 12px;
		@media (max-width: 1199px) {
				font-size: 10px;
				padding: 8px;
			}
		&::-webkit-input-placeholder {
			color: $black;
			opacity: 1;
			
		}
		&:-moz-placeholder {
			color: $black;
			opacity: 1;
		}
		&::-moz-placeholder {
			color: $black;
			opacity: 1;
		}
		&:-ms-input-placeholder {
			color: $black;
			opacity: 1;
		}
		&:focus {
			box-shadow: none;
			border-color: #ddd;
		}
	}
	.btn-primary {
		vertical-align:top;		
		padding: 0;

		&:after{
			font-family: 'Linearicons';
			content: '\e996';
			position: absolute;
			right: 21px;
			top: 16px;
			color: $primary;
			font-size: 14px;
			line-height: 16px;
	
		}
		&, &:visited {			
			color: $black;
			background: $white;
		}
		&:hover,
		&:focus,
		&:active	{
			color: $black;
			
		}
	}
}

.newsletter, .newsletter-popup {
	.newsletter-error, .newsletter-success {
		display: block;
		font-size: 12px;
		line-height: 14px;
		position: absolute;
		top: 100%;
		width:100%;
		margin-top:5px;
		@include transition(.3s all ease);
	}
}

.newsletter, .newsletter-popup {
	.newsletter-error{
		color: #bc2029;
		font-weight:700;
	}
	.newsletter-success{
		color: #55C65E;
		font-weight:700;
	}
}

//--------------------------------------------------------------
//            NewsLetter Block Responsive Layout
//--------------------------------------------------------------

@media (min-width: 768px) and (max-width: 991px) {
	.newsletter{
		input {
			padding-left:7px;			
			padding-right:5px;			
		}
		.zemez-login-form:before {
			display:none;
		}
	}
}
