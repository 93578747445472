//--------------------------------------------------------------
//                    NewsLetter Popup Module
//--------------------------------------------------------------

#zemez-newsletter-popup-form {
	@include clearfix;
}
.newsletter-popup-wrap {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10000;
	background: rgba(#000,.25);
	padding-top: 100px;
}

.newsletter-popup {
	position: relative;
    width: 440px;
    height: 324px;
    margin: 0 auto;
	background-color: rgba(#000,.8);
    background-repeat: no-repeat;
    padding: 55px 40px 10px;
    text-align: center;
	font-weight:400;
	a, a:visited, a:focus {
		color: $white;
	}
	a:hover {
		color: $textPrimary;
	}
	.zemez-login-form {
		position: relative;
		@include clearfix;
		font-size:0;
		line-height:0;
		margin-bottom:5px;
		display:inline-block;
		vertical-align:top;
		&:before {
			font-family: 'Linearicons';
			content: '\e996';
			position: absolute;
			left: 23px;
			top: 16px;
			color: $primary;
			font-size: 14px;
			line-height:16px;
		}
		input {
			width: 230px;
			height: 50px;
			@include box-sizing(border-box);
			border: none;
			background:$white;
			display: inline-block;
			font-size: 12px;
			line-height: 16px;
			margin-left:1px;
			margin-right:1px;
			color:$baseColor;
			padding-left:50px;
			vertical-align:top;
			@include border-radius(3px);
			&::-webkit-input-placeholder{
				color:$baseColor;
				opacity: 1;				
			}
			&:-moz-placeholder{
				color:$baseColor;
				opacity: 1;				
			}
			&::-moz-placeholder{
				color:$baseColor;
				opacity: 1;				
			}
			&:-ms-input-placeholder  {
				color:$baseColor;
				opacity: 1;				
			}
		}
	}
	.logo img {
		display: inline-block;
	}
	h3 {
		font:700 22px/24px $baseFontFamily;
		margin-bottom: 5px;
		color: $white;
		text-transform: none;
		+ p {
			margin-top:20px;			
		}
	}
	p {
		font-size:12px;
		line-height:21px;
		color: $white;
		margin-bottom:25px;
	}
	&-close-btn {
		background: none;
		outline: none;
		border: none;
		position: absolute;
		right: -34px;
		top: -35px;
		display:inline-block;
		width:35px;
		height:35px;
		font-size: 14px;
		line-height: 35px;
		text-align:center;
		@include border-radius(50%);
	}
	&-dont-show {
		bottom:56px;
		position:absolute;
		left:20px;
		right:20px;
	}
	.btn-primary {
		vertical-align:top;
		margin-left:1px;
		margin-right:1px;
	}
}

.newsletter-popup a.newsletter-popup-close-btn {
	&, &:visited {
		color: $white;
		background: $primary;
	}
	&:hover, &:focus, &:active {
		color: $white;
		background: $textPrimary;
	}
}

#zemez-newsletter-popup-form {
	position:relative;
}

html[lang="ru"] {
	.newsletter-popup .zemez-login-form input {
		width: 180px;
	}
}

html[lang="de"]{
	.newsletter-popup .zemez-login-form input {
		width: 180px;
	}
}


//--------------------------------------------------------------
//          NewsLetter Popup Module Responsive Layout
//--------------------------------------------------------------

.tablet, .mobile {
	.newsletter-popup-wrap{
		display: none !important;
	}
}

@media (max-width: 767px){
	.newsletter-popup-wrap{
		display: none !important;
	}
}