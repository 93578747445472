/*========================================================
                      Menu styles
=========================================================*/
@import "_floated-menu.scss";

@media (min-width: 992px) {
	.header_menu {
		text-align: left;
		[dir="rtl"] & {
			text-align: left;
		}
	}
}

.megamenu {
	padding:4px 0 8px;
	display: inline-block;
	margin-top: 13px;
	margin-bottom: 8px;
	
}

.sf-menu {
	list-style: none;
	margin: 0;
	padding: 0;
}

/*================ Menu  ===============*/
.sf-menu {
	> li {
		> a {
			text-transform:uppercase;
			font-weight: 700;
			@include border-radius(3px);
			font-size: 12px;
		}
	}
	li {
		position: relative;
		> a {
			&, &:visited, &:focus {
				color: $white;
			}
		}
		&.sfHover > a, > a:hover, > a.active {
			color: $black;
		}
		&.sf-with-mega {
			position: static;
		}
	}
}

/*================ Sub-menu lvl1  ===============*/
.sf-menu .simple_menu {
	list-style-type: none;
	display: none;
	position: absolute;
	z-index: 109;
	left: 0;
	text-align: left;
	[dir="rtl"] & {
		text-align: right;
	}
	html[dir="rtl"] & {
		left: auto;
		right: 0;
	}
	top: 100%;
	width: 200px;
	margin-top: 8px;
	padding: 0;
	font-size: 12px;
	line-height: 12px;
	@include box-sizing(border-box);
	@include border-radius(3px);
}

/*================ Sub-menu lvl1 item  ===============*/
.sf-menu .simple_menu { 
	li {
		position: relative;
	}
	a {
		display: block;
		background: rgba(#000,.8);
		color: #fff;
		padding: 10px 20px;
	}
	li.active > a, a:hover, li.sfHover > a {
		color: $primary;
		background: rgba(#000,.8);
	}
}

/*================ Sub-menu lvl2 ===============*/
.sf-menu .simple_menu .simple_menu {
	left: 100%;
	html[dir="rtl"] & {
		left: auto;
		right: 100%;
	}
	top: 0;
	margin-top: 0;
	&:before {
		display: none;
	}
}

.menu-gadget {
	display: none!important;
}

//--------------------------------------------------------------
//                      Megamenu Styles
//--------------------------------------------------------------
.sf-menu .sf-mega {
	display: none;
	position: absolute;
	list-style-type: none;
	top: 100%;
	margin-top:15px;
	left: 20px;
	right: 20px;
	z-index: 109;
	padding: 25px 22px 30px;
	color:$white;
	background-color: rgba(#000,.6);
	background-repeat: no-repeat;
	font-size: 12px;
	line-height: 12px;
	text-align: left;
	[dir="rtl"] & {
		text-align: right;
	}
	.container, [class*="col-"], .row {
		width: 100%;
		padding: 0;
		margin: 0;
	}
	> li {
		a {
			color: $white;
			background: transparent;
			&:hover {
				color: $primary;
			}
		}
	}
	ul {
		padding: 0;
		list-style-type: none;
		li {
			+ li {
				margin-top: 16px;
			}
		}
	}
	&_row {
		@include clearfix;
		margin: 0 -15px;
	}
	&_section {
		float: left;
		html[dir="rtl"] & {
			float: right;
		}
		padding: 0 45px;
	}
}

.submenu_title {
	text-transform:uppercase;
	font-weight:700;
}

//--------------------------------------------------------------
//                      sf-with-ul styles
//--------------------------------------------------------------
.sf-menu > li > .sf-with-ul {
	position: relative;
	&:before {
	}
}

.sf-menu ul > li > .sf-with-ul {
	position: relative;
	&:before {
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.sf-menu > li > a {
		font-size:12px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {	
	
}

@media (max-width: 767px) {
	.sf-menu {
		display: none;
	}
	.sf-mega_section{
		width: 100% !important;
	}
	.navigation {
		&.bg-primary {
			background:none;
		}
		.megamenu {
			padding:0;
		}
		.container {
			min-height:0;
		}
	}
}